/*how it works*/
.how-it-works {
    display: block;
  }
  
  .works-img {
    margin: 40px 0px;
  }
  
  .works-img img {
    width: 60%;
  }
  
  .samvaad-tech {
    display: block;
    padding: 48px 0px 96px 0px;
  }
  
  
  .main-flip-card-box {
    display: flex;
    flex-direction: row !important;
    margin-top: 40px;
  }
  
  .flip-card {
    perspective: 1000px; /* Enables 3D perspective */
    margin-right: 24px;
    height: 360px;
    width: 32%;
    padding: 24px;
    background: #02000D;
    border-radius: 24px;
    color: #fff;
    display: table;
  }
  
  .flip-card:last-child {
    margin-right: 0px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d; /* Enables 3D transforms */
  }
  
  .flip-card:focus {
    outline: 0;
  }
  
  .flip-card:hover .flip-card-inner,
  .flip-card:focus .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hides the other side during the flip */
    -webkit-backface-visibility: hidden; /* Safari-specific */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 24px;
  }
  
  .flip-card-front {
    background-color: #02000D; /* Adjust front background */
  }
  
  .flip-card-back {
    background-color: #111; /* Adjust back background */
    transform: rotateY(180deg); /* Flip the back face */
  }
  
  
  
  .flip-card-front {
    /*  background: linear-gradient(to left, #4364f7, #6fb1fc);*/
    background: #000;
    z-index: 2;
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    /*  border-radius: 24px;*/
    /*  display: table;*/
    padding: 48px 0px;
  
  
  }
  
  .flip-card-front span {
    width: 100%;
    display: block;
    text-align: center;
  }
  
  .flip-card-back {
    /*  background: linear-gradient(to right, #4364f7, #6fb1fc);*/
    background: #000;
    transform: rotateY(180deg);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  
  .flip-card-front h3,
  .flip-card-back h3 {
    font: 600 24px/40px "DM Sans", Helvetica;
    color: #fff;
    margin-top: 24px;
  }
  
  .flip-card-back h3 {
    bottom: 0px;
    text-align: center;
    display: block;
    width: 100%;
  }
  
  
  .flip-card-back p {
    font: 500 16px/27px "DM Sans", Helvetica;
    display: block;
    text-align: left;
    color: #fff;
  }
  
  .show-desktop {
    width: 100%;
  }
  
  .show-mweb {
    display: none;
  }
  
  .samvaad-action {
    width: 100%;
    background: #02000D;
    padding: 96px 0px 0px 0px;
    text-align: center;
  }
  
  .samvaad-action ul{
    margin: 40px 0px 40px 0px;
    padding: 0px;
  }
  
  .samvaad-action ul li{
    font: 500 20px/24px "DM Sans", Helvetica;
    display: inline-block;
    border-radius: 46px;
    padding: 16px 32px;
    background: #1E1C28;
    color: #4A4952;
    vertical-align: middle;
    position: relative;
    z-index: 3;
  }
  
  .samvaad-action ul li:not(:last-of-type){ 
    margin-right: 132px;
  
  }
  
  .samvaad-action ul li i{
    vertical-align: middle;
    margin-right: 8px;
  }
  
  .samvaad-action ul li i img{
    opacity: 0.4;
  }
  
  
  /*active tab class*/
  .samvaad-action ul li.active{
    color: #fff;
  }
  
  .samvaad-action ul li.active i img{
    opacity: 1.0;
  }
  
  
  .samvaad-action ul li.active:not(:last-of-type)::after{
    content: "";
    position: absolute;
    background:#fff;
    width: 140px;
    height: 2px;
    top: 50%;
    right: -140px;
    z-index: 2;
  }
  
  /*active tab class*/
  
  .samvaad-action ul li:not(:last-of-type)::after{
    content: "";
    position: absolute;
    background:#35343E;
    width: 140px;
    height: 2px;
    top: 50%;
    right: -140px;
    z-index: 2;
  }
  
  
  .samvaad-action ul .caption-text {
    font: 500 20px/32px "DM Sans", Helvetica;
    display: block;
    width: 100%;
    text-align: center;
    color: #4CD964;
    margin-top: 24px;
  }
  
  
  .samvaad-action .scroll-box {
    width: 1180px;
    margin:0px auto;
    overflow: auto;
  /*  border:1px solid #f00;*/
  display: block;
  height: 320px;
  }
  
  .samvaad-action .benefit-md-box {
    width: 60%;
    display: inline-block;
    margin: 0px auto;
  }
  
  .samvaad-action .benefit-md-box img{
    width: 100%;
    margin-bottom: 40px;
  }
  
  .samvaad-action .benefit-md-box img:last-child{
    margin-bottom: 0px;
  }
  
  
  .how-it-works-faq {
    width: 1180px;
    margin:0px auto;
    padding: 96px 0px !important;
  }
  
  .personal-assistant {
    width: 100%;
    padding: 96px 5% 0px 5%;
    background: #02000D;
    position: relative;
    display: flex;
    flex-direction:row;
  }
  
  .pt-lt-box {
    width: 50%;
  /*  display: inline-block;*/
  /*  overflow: auto;
    border: 1px solid #f00;*/
  }
  
  .pt-lt-box h2{
    text-align: left;
  }
  
  .pt-lt-box p{
    font: 500 20px/30px "DM Sans", Helvetica;
    color: #B3B2B6;
    text-align: left;
    margin: 16px 0px;
  }
  
  .pt-lt-box .contact-cta{
    text-align: left;
  }
  
  
  .pt-rt-box { 
    width: 40%;
  /*  display: inline-block;*/
  /*  overflow: auto;
    border: 1px solid #f00;*/
  /*  position: absolute;*/
  /*  right: 10%;*/
    bottom: 0;
  }
  
  
  .pt-rt-box img{ 
    width: 100%;
  
  }
  
  
  
  @media (992px <= width <= 1199px) {
    .how-it-works-faq {  
    width:960px !important;
    }
  
    .personal-assistant {
      padding:96px 5% 0px 5%
    }
  
    .pt-lt-box h2 {
      font-size: 40px;
    }
  
    .pt-lt-box {
      width: 50%;
    }
  
    .pt-rt-box {
      width: 50%;
      right:0;
    }
  
  }
  
  
  @media (768px <=width <=991px) {
  
    .how-it-works-faq { 
      width: 720px;
    }
  
    .main-flip-card-box {
      flex-direction: column !important;
    }
  
    .flip-card {
      width: 100%;
      height: 300px;
      margin-right: 0px;
      margin-bottom: 24px;
    }
  
    .samvaad-action ul li{
      padding: 16px;
    }
  
    .samvaad-action ul li:not(:last-of-type){
      margin-right: 90px;
    }
  
    .samvaad-action ul li.active:not(:last-of-type)::after {
      width: 100px;
      right: -100px;
    }
  
   .personal-assistant {
      padding:48px 24px 0px 24px;
    }
  
    .pt-lt-box h2 {
      font-size: 24px;
      line-height: 32px;
    }
  
    .pt-lt-box p {
      font-size: 16px;
      line-height: 24px;
    }
  
    .pt-lt-box {
      width: 50%;
    }
  
    .pt-rt-box {
      width: 50%;
  /*    right: 5%;*/
    }
  
  }
  
  
  
  @media (max-width: 768px) {
  
    .show-desktop {
      display: none;
    }
  
    .show-mweb {
      display: block;
      width: 100%;
    }
  
  
    .works-img img {
      width: 100%;
    }
  
  
    .samvaad-tech {
      padding: 0px;
    }
  
  
    .main-flip-card-mweb-box {
      display: block !important;
      margin-top: 24px;
    }
  
  
    .main-flip-card-mweb-box .flip-card {
      width: 100%;
      height: auto !important;
      margin-bottom: 16px;
    }
  
    .main-flip-card-mweb-box .flip-card:last-child {
      margin-bottom: 0px;
    }
  
  
    .main-flip-card-mweb-box .flip-content {
      display: block;
      width: 100%;
      margin-bottom: 16px;
      text-align: left;
    }
  
    .flip-content span {
      display: inline-block;
      width: 36px;
      height: 36px;
      vertical-align: middle;
    }
  
    .flip-content span img {
      width: 100%;
      height: 100%;
    }
  
  
    .flip-content h3 {
      display: inline-block;
      margin-left: 12px;
      font: 900 20px/26px "DM Sans", Helvetica;
      vertical-align: middle;
    }
  
    .flip-card p {
      width: 100%;
      display: block;
      font: 500 16px/24px "DM Sans", Helvetica;
      text-align: left;
  
    }
  
  
    .samvaad-action ul li{
      font-size: 12px;
    }
  
  
    .samvaad-action ul li{
      padding: 8px 16px;
    }
  
    .samvaad-action ul li i {
      margin:6px 0px 0px 0px;
      display: inline-block;
    }
  
    .samvaad-action ul li i img{ 
      width: 16px;
      height: 16px;
      margin-right: 4px;
  
    }
  
    .samvaad-action ul li:not(:last-of-type){
      margin-right: 10px;
    }
  
    .samvaad-action ul li.active:not(:last-of-type)::after {
      width: 12px;
      right: -10px;
    }
  
    .samvaad-action ul li:not(:last-of-type)::after {
      width: 12px;
      right: -10px;
    }
  
    .samvaad-action ul .caption-text {
      font:500 18px/24px "DM Sans", Helvetica;
      padding: 0px 16px;
    }

    .personal-assistant {
      flex-direction: column !important;
      padding-top: 48px !important;
    }

    .pt-lt-box {
      width:100%;
    }

    .pt-lt-box h2{
      text-align: left !important;
    }

    .pt-rt-box{
      width: 100%;
      position: relative;
      right: 0px;
    }

    .how-it-works-faq { 
      width: 100%;
      padding: 48px 24px !important;
    }
  

}