.stack-wrapper {
    border-radius: 56px 56px 0px 0px;
    background: #03010E;
    position: relative;
    width: 100%;
    padding:96px 0px;
    margin:0px auto;
  }

  .stack-container {
    display: block;
    height: 100%;
    }
  
  .feature-wrapper {
    display: block;
  }
  

.feature-text{
    color: #CECED1;
    font: 700 20px /26px "DM Sans", Helvetica;
    letter-spacing: 2px;
    position: relative;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

.main-heading-white {
  font:700 52px/60px "Uxum Grotesque-Bold", Helvetica;
  letter-spacing: 1.6px;
  text-align: center;
  display: block;
  color: #fff;
}


.main-features {  
  display: block;
  padding-top:76px;
  text-align: center;
  width:1400px;
  margin:0px auto;
}


/*1featuer*/
.personalise-outer {
    display: inline-block;
    width:463px;
    height: 320px;
    border: 2px solid #31303A; 
    border-radius: 24px;   
    vertical-align: top;
    margin-right:28px;
    margin-bottom:24px;
  }
  
  .personalise-inner {
    position: relative;
    /*display: block;*/
    height: 100%;
    padding: 50px 50px 40px 50px;
  }

  .addicon {
    position: absolute;
    right: 16px;
    top:16px;
    cursor: zoom-in;
    z-index: 9;
  }
  

.feature-plus-icon{
  display: inline-block;
  width: 32px;
  height: 32px;
}

  .feature-plus-icon img{
    /*width: 100%;*/
    position: absolute;
    top:16px;
    right:16px;
  }
  
  .personalise-inner-div {
    /*height: 266px;*/
    padding:0px;
    width: 100%;
  }
  
  .personalise-text-div {
    text-align: left;
  }
  


.title-text {
    color: #ffffffcc;
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 8px;
  }
  
  .subtitle-text {
    align-self: stretch;
    color: #ffffff80;
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    position: relative;
  }


.img-voice {
    height: 62px;
    position: absolute;
    bottom: 40px;
    left: 0px;
    width: 100%;
  }


/*2featuer*/
.transcript-div {
    display: inline-block;
    width:373px;
    height: 320px;    
    border-radius: 24px;   
    vertical-align: top;
    margin-right:24px;
    margin-bottom:24px;

  }
  
  .tramscript-div-inner {
/*    background-color: #f5f5f6;*/
    height: 100%;
    position: relative;
  }
  
  .tramscript-img-inner {
    background-image: url('/public/img/home-girl.png');
    background-size:cover;
    height: 100%;
    position: relative;
    width: 100%;
    border-radius: 24px;
  }


  .tramscript-img-inner .bg-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(3, 1, 13, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index:8;
  border-radius: 24px;
}

.bg-overlay .transcripts-and {
    color: #D3D1D0;
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;    
    position: relative;
    text-align: center;
    width: 100%;
    padding: 0px 36px;
    bottom: 24px;
    position: absolute;;
  }




  /*3featuer*/
.categorise-div {
     display: inline-block;
    width:388px;
    height: 320px;    
    border: 2px solid #31303A; 
    border-radius: 24px;   
    vertical-align: top;
    margin-bottom:24px;

  }
  
  .categoirse-inner {
    position: relative;
    height: 100%;
  }
  
 
  
  .categoriese-inner-div {
    padding:40px 50px 40px 40px;
    width: 100%;
  }

  .tag-icon{
    margin:20px auto;
  }

  .category-features-tabs {
    margin-top:24px;
  }

  .category-features-tabs span{
    display: inline-block;
    padding: 8px 16px;
    color:#FFFFFFB2;
    border: 1px solid #DEE6F5;
    border-radius: 100px;
    margin-right: 6px;
    margin-bottom: 12px;
    vertical-align: middle;
    height: 38px;
  }

  .category-features-tabs img{
    vertical-align: sub;
  }
  
  .categorise-text-div {
    display: block;
  }
  
 /* .img-voice {
    align-self: stretch;
    height: 62px;
    margin-bottom:4px;
    position: relative;
    width: 100%;
  }
*/





/* 4 Feature */
.fraud-wrapper {
   display: inline-block;
    width:411px;
    height: 422px;
    border: 2px solid #31303A; 
    border-radius: 24px;   
    vertical-align: top;
    margin-right:28px;
    margin-bottom:24px;
    overflow:hidden;
  }


  .div-spam-inner {
    position: relative;
    height: 100%;
    padding:40px 50px 40px 40px;
    text-align: left;
  
  }
  
  
  .fraud-div {
    height: 91px;
    transform: rotate(13.11deg);
    width: 189px;
    position: absolute;
    right:20px;
    bottom:80px;


  }
  
  .fraud-div-inner {
    align-items: flex-start;
    background: #1D1B27;
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
     padding: 12px 24px;
    position: relative;
     transform: rotate(-15.12deg);
  }
  
  .insurance-div-small {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 9.82px;
    position: relative;
    width: 100%;
  }
  
  .phone-x {
    height: 20.06px;
    margin-bottom: -12.58px;
    position: relative;
    transform: rotate(-11.09deg);
    width: 20.55px;
  }
  
  .text-wrapper-sm {
    color: #ffffff80;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.23px;
    position: relative;
    width: fit-content;
  }
  
  .cc-div {
    height: 82px;
    transform: rotate(5.09deg);
    width: 230px;
    position: absolute;
    right:48px;
    bottom:48px;
  }
  
  .insurance-div-inner {
    align-items: flex-start;
    background: #1D1B27;
    opacity: 0.8;
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    gap: 12.27px;
    left: 3px;
    padding: 12px 24px;
    position: relative;
    top: 18px;
    transform: rotate(9.65deg);
  }
  
  .phone-x-2 {
    height: 23.8px;
    margin-bottom: -8.36px;
    margin-left: -2.2px;
    position: relative;
    transform: rotate(-19.84deg);
    width: 24.64px;
  }
  
  .loan-div {
    height: 45px;
    transform: rotate(-18.86deg);
    /*width: 172px;*/
     position: absolute;
    left:0px;
    bottom:20px;
  }
  
  .loan-div-inner {
    align-items: flex-start;
    background: #1D1B27;
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    padding: 12px 24px;
    position: relative;
  }
  
  .phone-x-3 {
    height: 24px;
    margin-top: 0px;
    position: relative;
    transform: rotate(37.71deg);
    width: 24.93px;
  }
  


  .insurance-div {
    height: 70px;
    width: 219px;
    position: absolute;
    right:0px;
    bottom:0px;
  }
  
  
  .phone-x-4 {
    height: 22px;
    position: relative;
    transform: rotate(-6.45deg);
    width: 22px;
  }
  


 


/* 5 and 6 Feature */

.two-feature {
    display: inline-block;
    width:300px;
    height: 422px;
    vertical-align: top;
    margin-right:28px;
    margin-bottom:24px;
}


  
  .div-assitance-wrapper {
    background: #1B1925;
    border-radius: 24px;
    position: relative;
    width: 100%;
    height: 235px;
    margin-bottom: 24px;
    border-radius: 24px;  
    
  }
  
  
  .personal-AI {
    color: #ffffffcc;
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 700;
    position: relative;
    text-align: center;    
    top: 43%;
    /*height: 100%;*/
    text-align: center;
    width: 100%;
  }




.summary-wrapper {
    border-radius: 24px;
    height: 163px;  
    position: relative;
    display: block;
    width: 100%;
  }
  
  
  .summary-male {
    height: 100%;
    width: 100%;
    border-radius: 24px;
  }
  
  .summary-overlay {
    height: 100%;
    width:100;
    display: block;
  }


  .summary-overlay .bg-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(3, 1, 13, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index:8;
  border-radius: 24px;
}

  .get-summary-of-all {
    color: #ffffffcc;
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 700;    
    line-height: normal;
    position: absolute;
    bottom: 50px;
    right: 20px;
    width: 50%;
    text-align: left;

  }




/*7 feature*/

.automate-div {
    height: 422px;    
    width: 513px;
    display: inline-block;
    border: 2px solid #31303A; 
    border-radius: 24px;   
    vertical-align: top;
/*    margin-right:24px;*/
    margin-bottom:24px;
  }
  
  .div-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
    padding:40px 50px 40px 40px;

  }
  

  
  .div-client {
    width: 100%;
    text-align: center;
    margin-top:40px;
  }
  
  .div-client-inner {
    background:#2D2B36;
    border-radius: 24px;
    height: 170px;
    width: 125px;
    display: inline-block;
    text-align: center;
    padding: 32px 0px;
    vertical-align: top;
    margin-right:16px;

  }


  .image-wrapper-client {
    display: block;
    text-align: center;
    margin-bottom: 12px;
  }
  
  .client-image {
    height: 36px;
    width: 36px;
    object-fit: cover;
    border-radius: 24px;
   
  }
  
  .div-client-text {
    display: block;
    }
  
  .client-text-time {
    
    color: #ffffff80;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    display: block;
    
  }
  
  .client-text- {
    color: #ffffff80;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  

  .div-answered {
    background: #2D2B36;
    border-radius: 24px;
    display:inline-block;
    text-align: center;
    height: 170px;
    width: 172px;  
    padding: 32px 16px;
    vertical-align: top;    
  }

 
  
  .div-answered-inner {
    display: block;
    text-align: center;
    margin-bottom: 12px;
  }
  
  .ic-call {
    height: 36px !important;
    position: relative !important;
    width: 36px !important;
  }


    .answered-by-samvaad {
    color: #ffffff80;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    display: block;

    
  }


  /*7 feature end here*/


/*8 feature*/
.social-app-wrapper {
    background: #1B1925;
    display: inline-block;
    width:630px;
    height: 320px;
    /*border: 2px solid #31303A; */
    border-radius: 24px;   
    vertical-align: top;
    margin-right:28px;
  }
  
  .social-app-wrapper-inner {
    position: relative;
    height: 100%;
    padding:40px 50px 40px 40px;
    text-align: left;

  }
  

  .social-app-div {
    position: relative;
    width: 100%;    
    text-align: center;
    margin-top: 100px;
  }
  
  .social-cal-sm {
    height: 48px;
    object-fit: cover;
    width: 48px;
    display: inline-block;
    margin-right: 48px;
  }
  
  .social-gmail-sm {
    height: 48px;
    object-fit: cover;
    width: 64px;
    display: inline-block;
    margin-right: 48px;
  }
  
  .subtract {
    height: 48px;
    width: 48px;
    display: inline-block;
  }




 
 /*9 feature*/
  
  .own-data-wrapper {
    height: 320px;
    width: 630px;
    display: inline-block;
    vertical-align: top;
    border: 2px solid #31303A; 
    border-radius: 24px;  
  }

  
  .own-data-wrapper-inner {
    position: relative;
    height: 100%;
    padding:40px 50px 40px 40px;
    text-align: left;

  }
  

  .div-delete {
    margin-top: 80px;
    text-align: center;
    display: block;

}
  
  .div-delete-wrapper {   
    border: 1px dashed #ffffff99;
    border-radius: 8px;
    padding: 12px 16px;
    width: 225px;
    text-align: center;
    margin:0px auto;
    color: #ffffff80;
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 600;
    position: relative;
    
  }

  
  .cursor-click {
    height: 36px;
    position: absolute;
    width: 36px;
    right:0px;
    bottom: -15px;
  }
  

/*popup*/
.modal-overlay{
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  backdrop-filter:blur(8px);
  
}

.main-popup {
  width: 1000px;
  height: 660px;
  background:#fff;
  border-radius: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin:5% auto 0 auto;
  position: relative;
}



.modal-tabs{
  width: 300px;
  height: 100%;
  background:#02000D; 
  display: inline-block;
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
}

.modal-tabs ul{
  margin:0px;
  padding:0px;
}

.modal-tabs ul li{
  display: flex;
  width:100%;
  color: #fff;
  padding:20px 24px;
  vertical-align: middle;
  font-family: DM Sans;
font-size: 20px;
font-weight: 700;
line-height: 26px;
}

.modal-tabs ul li.active{
  background:#1B1925;
}

.list-item-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right:8px;
}

.modal-tabs ul li:first-child {
  border-top-left-radius:32px;

}


.modal-content{
  width: 680px;
  display: inline-block;
  height: 100%;
  vertical-align: top;
  padding:24px 40px 24px 40px;
  height: 650px;
  overflow-y: scroll;
  position: relative;
}

.modal-content ul{
  list-style: none;
  margin:24px 0px 24px 0px;
  padding:0px 16px 0px 16px;
}

.modal-content ul li{
  list-style-type: inside;
/*   display: block;*/
  width:100%;
  color: #000;
  font-family: DM Sans;
font-size: 16px;
font-weight: 400;
line-height: 24px;
margin-bottom: 16px;

}

.modal-content-img {
  width: 100%;
  text-align: center;
}

.modal-close {
  position: absolute;
  top:-54px;
  right:0px;
  z-index: 20;
  border: none;
  background: transparent;
}

.modal-content hr{
  width: 100%;
  border:1px solid #E6E6E6;
  height: 1px;
  width: 100%;
  margin:0px;
}


  /* Greater than or equal to 1200px */
@media (width >= 1400px) {
  .main-features {  
  width:1400px;
}
}



@media (1200px <= width <= 1399px) { 
  .main-features {  
  padding-top:76px !important;
  width:1180px !important;
}


.personalise-outer{
  width:330px !important;
  height: 266px;
}

.img-voice {
  bottom: 10px !important;
}

.personalise-inner,
.categoriese-inner-div,
.div-spam-inner,
.div-inner,
.social-app-wrapper-inner,
.own-data-wrapper-inner
 {
  padding: 20px 24px 20px 24px;
}

.category-features-tabs span {
  padding:4px 8px;
  height: 32px;
}

.transcript-div {
  width:330px !important;
  height: 266px !important;
}

.categorise-div {
  width: 330px !important;
  height: 266px;
}

.fraud-wrapper {
  width: 330px !important;
  height: 422px !important;
}


.two-feature {
  width: 330px !important;
  height: 422px !important;
}


.div-client-inner{
  width: 120px !important;
}

.div-answered{
  width: 140px !important;
}

.div-assitance-wrapper,
.summary-wrapper
 {
  width: 100%;
}


.automate-div {
  width: 330px !important;
  height: 422px !important;
}



.social-app-wrapper{
  width: 525px !important;
  height: 320px !important;
}

.own-data-wrapper{
  width: 525px !important;
  margin-right:0px !important;
  height: 320px !important;
}

.main-popup {
  margin:6% auto 0 auto;
}

}

@media (992px <= width <= 1199px) { 

.main-features {
  width:960px;
}


.personalise-outer {
  width: 300px;
  margin-right:24px;
}

.transcript-div {
  width:300px;
  margin-right:24px;
}

.categorise-div {
  width: 300px;
}

.categoriese-inner-div{
  padding:50px 16px 0px 16px;
}

.category-features-tabs span {
  padding:8px;
}


.fraud-wrapper{
  width: 300px;
}

.two-feature{
  width: 300px;
}

.automate-div {
  width: 300px;
}

.div-inner{
  padding:30px 24px 30px 24px;
}

.div-client-inner {
  width: 100px;
  margin-right: 8px;
}
.div-answered{
  width:140px;
}

.social-app-wrapper {
  width: 460px;
}

.own-data-wrapper{
  width:460px;
}


}


@media (768px <= width <= 991px) { 
.main-features {
  width:720px;
  }

.personalise-outer,
.personalise-text-div,
.transcript-div,
.tramscript-img-inner,
.tramscript-rectangle,
.transcripts-and-wrapper,
.transcripts-and,
.categorise-div,
.fraud-wrapper,
.two-feature,
.summary-overlay,
.get-summary-of-all-wrapper,
.automate-div,
.social-app-wrapper,
.own-data-wrapper
{
  width:100% !important;
  margin-right: 0px;

}

.social-app-wrapper  {
  margin-bottom:16px;
  margin-right: 0px;
}

.summary-wrapper img{
  width:100%;
  object-fit: cover;

}



}



/* Less than or equal to 700px */
@media (width <= 767px) {
  .main-features {
  padding-top:36px !important;
  width:100% !important;
}


.stack-wrapper {
  padding:36px 24px !important;
  border-radius: 32px 32px 0px 0px;
}

.feature-text{
        font-size: 16px !important;
        line-height: 20px !important;
}

.main-heading-white {
        font: 700 32px/40px "Uxum Grotesque-Bold", Helvetica !important;
        letter-spacing: 1.6px !important;
        text-align: center !important;
        display: block!important; 
}


.personalise-outer,
.personalise-text-div,
.transcript-div,
.tramscript-img-inner,
.tramscript-rectangle,
.transcripts-and-wrapper,
.transcripts-and,
.categorise-div,
.fraud-wrapper,
.two-feature,
.summary-overlay,
.get-summary-of-all-wrapper,
.automate-div,
.social-app-wrapper
{
  width:100% !important;

}

.fraud-div {
  bottom: 100px;


}

.cc-div{
  right: 10px;
  bottom: 52px;
}




.personalise-outer{
  margin-right:0px !important; 
  height: 260px!important;
}

.personalise-inner { 
padding: 36px 24px!important;
}

.img-voice { 
  bottom: 20px!important;

}

.title-text { 
  font-size: 18px!important;
  line-height: 24px!important;
}

.subtitle-text { 
  font-size: 16px!important;
  line-height: 24px!important;

}



.transcript-div {
  margin-right:0px !important; 
  display: block !important;
  height: 260px!important;
}


.transcripts-and {
    font-size: 18px !important;    
}



.categorise-div {
  margin-right:0px !important; 
  display: block !important;
  height:280px!important;
  width: 100%!important;
}


.categoriese-inner-div { 
  padding: 36px 24px!important;
}



.fraud-wrapper {
  margin-right:0px !important; 
  display: block !important;
  height: 360px !important;
  width: 100% !important;
}


.div-spam-inner { 
  padding: 36px 24px !important;
}


.two-feature{
  margin-right:0px !important; 
  display: block !important;
  height: 380px !important;
  width: 100% !important;
}

.div-assitance-wrapper{
  height: 180px !important;
}


.summary-wrapper {
    height: 176px !important;
}




.automate-div {
  margin-right:0px !important; 
  display: block !important;
  height: 360px !important;
  width: 100% !important;
}


.div-inner{
  padding: 36px 24px !important;
}


.social-app-wrapper{
  margin-right:0px !important; 
  display: block !important;
  height: 260px !important;
  width: 100% !important;
  margin-bottom: 24px !important;
}



 .social-app-wrapper-inner {
    padding: 36px 24px !important;
  }
  
  .social-app-div {
    margin-top: 60px;
  }


.own-data-wrapper{
  display: block !important;
  height: 260px !important;
  width: 100% !important;
}


 .own-data-wrapper-inner {
    padding: 36px 24px !important;
  }
  
  .div-delete {
    margin-top: 40px;
  }

  .div-client-inner{
  width: 40%;
}


.div-answered {
  padding:32px 8px;
  width:50%;

}


/*popup css*/

.modal-overlay{
  display: flex;
  align-items: end;
}
.main-popup{
  width: 100%;
  height: 550px;
  display: block;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}


.modal-tabs {
  width: 100%;
  height: 90px;
  display: block;
  overflow-x:scroll;
  border-radius: 24px 24px 0px 0px;
}

.modal-content{
  width: 100%;
  height: 100%;
  display: block;
  padding:0px 16px;
  background: #fff;
}

.modal-tabs ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.modal-tabs ul li{
  white-space: nowrap;
  width: auto;
  display: inline;
  height: 90px;
/*  line-height: 90px;*/
  padding: 32px;
  font-size: 16px;
}

.modal-content-img img{
  width: 100%;
}



}



/* Less than or equal to 700px */
@media (width <= 430px) { 

.categorise-div {
  height: 100% !important;
}

.div-client-inner{
  width: 40%;
}


.div-answered {
  padding:32px 8px;
  width:50%;

}


}


  
  
  
  
  
  


