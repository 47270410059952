/*.privacy-and-security{
  padding-bottom: 40px;
}*/

.desc-text {
font-family: DM Sans;
font-size: 20px;
font-weight: 500;
line-height: 30px;
text-align: center;
margin:16px 12%;
}


.img-privacy {
    width: 100%;
    text-align: center;
    margin:80px 0px;
  }


.data-widget {
  background: #02000D;
  padding: 284px 20%;
  text-align: center;
}

.data-widget h3 {
  font: 600 64px/80px "DM Sans", Helvetica;
  text-align: center;
  color: #35333C;
  display: inline; /* This will prevent each word from going to the next line */
  margin: 0; /* Remove any default margins */
}

.data-widget h3 caption{
  color:#E6E5E7;
  display: inline-block;
}


.consent-widget {
  width: 1400px;
  margin:0px auto;
  padding: 96px 8%;
  display: flex; 
  flex-direction: row;
}


.cont-left-panel {
  padding:0px 48px;
  width: 60%;
}


.cont-left-panel h3{
  font: 600 64px/80px "Uxum Grotesque", Helvetica;
  text-align: left;
  color:#35333C;
}

.cont-left-panel p{
  font-family: DM Sans;
font-size: 20px;
font-weight: 500;
line-height: 30px;
text-align: left;
margin:16px 0px;
}

.cont-img-panel {
  width: 40%;
}

.cont-img-panel img{
  width: 100%;
}


/*popup cards widget*/

.cards-main-widget{
  width: 1180px;
  margin:0px auto;
  padding: 96px 0;
  
}

.cards-main-widget ul {
  margin:0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
}

.cards-main-widget ul li{
/*  display: inline-flex;*/
position: relative;
  margin-right: 24px;
  height: 400px;
  width: 32%;
  padding: 0px 40px;
  background: #02000D;
  border-radius: 24px;
  color: #fff;
   display: table;
}


.cards-main-widget ul li h3{ 
  font-family: DM Sans;
font-size: 48px;
font-weight: 700;
line-height: 62px;
text-align: left;
 display: table-cell;
  vertical-align: middle;
}

.cards-main-widget ul li:last-child{ 
  margin-right:0px;
}

.protected-widget {
  width: 1180px;
  padding: 96px 4%;
  margin:0px auto;
}


.privacy-lottie-widget{
  width: 100%;
  padding: 288px 0;
  margin:0px auto;
  background: #000;
  text-align: center;
}

.privacy-lottie-widget .lock{
  margin-bottom: 40px;
}


.privacy-lottie-widget h4{
font:700 52px/80px "Uxum Grotesque-Bold", Helvetica;
text-align: center;
display: block;
color: #fff;
text-transform: uppercase;
letter-spacing: 40px;
}


.learn-more-btn {
  display: flex;
  margin-top: 24px;
}

.learn-more-btn a{
  font:500 16px/48px "DM Sans", Helvetica;
  width: 183px;
height: 48px;
border-radius: 62px;
text-align: center;
display: inline-block;
border: 1px solid #000;
line-height: 48px;
color: #000;
margin:0px auto;
cursor: pointer;
}

.questioned-widget {
  background: #02000D;
  padding: 135px 6%;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.questioned-widget h2{
  text-align: left !important;
  width: 70%;
}

.contact-support {
  width: 314px;
  height: 136px;
  background: #22212B;
  border-radius: 20px;
  padding: 32px;
  position: relative;
}

.contact-support caption,
.contact-support p
{
font-family: DM Sans;
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;
color:#fff;
display: block;
}

.contact-support p {
  font-size: 24px !important; 
  margin-top: 20px;
}


.main-question-icon{
  position: absolute;
}


.question-icon-01{
  top: 25px;
  left: -10px;
}

.question-icon-02{
  top:0px;
  left: 491px;
  opacity: 0.7;
}


.question-icon-03{
  top:27px;
  left: 970px;
  opacity: 0.5;
}


.question-icon-04{
  top:32px;
  right: 0px;
  opacity: 0.9;
}

.question-icon-05{
  bottom:-32px;
  left: 36px;
  opacity: 0.8;
}


.question-icon-06{
  bottom:15px;
  right:600px;
  opacity: 0.6;
}


.question-icon-07{
  bottom:34px;
  right:48px;
  opacity: 0.7;
}

/*modal css*/
.inner-modal .main-popup {
  height: 500px !important;
  background: #000 !important;
  width: 800px !important;
}

.inner-modal .modal-content {
  width: 100% !important;
  height: 500px !important;
}

.inner-modal .modal-content ul { 
  padding:0px 0px 0px 24px;

}


.inner-modal .modal-content ul li{
  color: #fff !important;
  list-style-type: disc !important;
  font-size: 20px !important;
  line-height: 30px !important;
}


.inner-modal .modal-content h3 {
  Font: 500 40px/52px "DM Sans", Helvetica;
  color: #fff !important;
/*  vertical-align: middle;*/
margin-bottom: 48px;
}

.inner-modal .modal-content img{
  vertical-align: middle;
  margin-right:24px;
}

.inner-modal .modal-content p{
  color: #fff !important;
  font-size: 16px !important;
  line-height: 30px !important;
}


/* Greater than or equal to 1200px */
@media (width >= 1400px) {
.consent-widget {  
  width:1400px !important;
}


.inner-modal .main-popup {
  margin:14% auto !important;
}
}


@media (1200px <= width <= 1399px) {  

  .consent-widget {
  width: 1180px;
  padding: 72px 4%;
}

.cards-main-widget{ 
  width: 1180px;
  padding: 72px 0;
  }

  .protected-widget {
  width: 1180px;
  padding: 72px 4%;
}

}


@media (992px <= width <= 1199px) { 

  .consent-widget {
  width: 960px;
  padding: 72px 0px;
}

.cont-left-panel h3 {
  font:600 48px/56px "Uxum Grotesque", Helvetica;
}

.cards-main-widget{ 
  width: 980px;
  padding: 72px 0;
}

.cards-main-widget ul li {
  height: 300px;
  padding: 0 40px;
}

.cards-main-widget ul li h3 {
  font-size:32px;
  line-height: 40px;
}

.protected-widget {
  width: 960px;
  padding: 72px 0; 
}



}



@media (768px <= width <= 991px) { 

.consent-widget {
  width: 720px;
  padding:40px 0px;
  flex-direction: column;
}

.cont-left-panel,
.cont-img-panel
 {
  width: 100%;
}

.cont-left-panel {
  margin-bottom:24px;
}

.cont-left-panel h3 {
  font:600 40px/56px "Uxum Grotesque", Helvetica;
}


.cards-main-widget{ 
  width: 720px;
  padding: 40px 0;
}

.cards-main-widget ul li {
  height: 260px;
  padding: 0 24px;
}

.cards-main-widget ul li h3 {
  font-size:32px;
  line-height: 40px;
}

.protected-widget {
  width: 720px;
  padding: 40px 0; 
}



}


@media (max-width: 768px) { 

.desc-text {
font-family: DM Sans;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: center;
margin:16px;

  }

  .img-privacy { 
    margin:40px 0px;
  }


.data-widget {
  padding: 140px 24%;

}

  .data-widget h3{ 
  font: 600 40px/52px "DM Sans", Helvetica;
  text-align: center;
  color:#35333C;
}

.consent-widget {
  width: 100%;
  background-color:#fff;
  padding: 36px 0px;
  flex-direction: column;
}

.cont-left-panel,
.cont-img-panel
 {
  width: 100%;
  text-align: center;
}

.cont-left-panel {
  margin-bottom:24px;
  padding:0px 24px;
}

.cont-left-panel h3 {
  font:600 32px/36px "Uxum Grotesque", Helvetica;
  text-align: center;
}

.cont-left-panel p{
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}

.cont-img-panel img {
  width: 80%;
  text-align: center;
  margin:0px auto;
}


.cards-main-widget{
  width: 100%;
  padding: 0px;
}

.cards-main-widget ul {
  margin:0px;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;

}

.cards-main-widget ul li{
  width: 100%;
  margin:0px 0px 24px 0px; 
  padding: 0 40px;

}

.protected-widget {
  width: 100%;
  padding: 64px 0; 
}

.privacy-lottie-widget {
  padding: 264px 24px;
}


.privacy-lottie-widget h4{
font:700 32px/48px "Uxum Grotesque-Bold", Helvetica;
letter-spacing: 16px;
}


.questioned-widget {
  background: #02000D;
  padding: 120px 24px;
  flex-direction:column;
}


.questioned-widget h2{
  width: 100%;
  text-align: center !important;
}

.contact-support {
  margin:24px auto;
}



.question-icon-01{
  top: 25px;
  left: -25px;
}

.question-icon-02{
  top:0px;
  left: 40%;
  opacity: 0.7;
}


.question-icon-03{
  top:27px;
  right:5%;
  left: auto;
  opacity: 0.5;
}


.question-icon-04{
  display: none;
}

.question-icon-05{
  opacity: 0.6;
  top: auto;
  bottom:10px;
  left: -25px;
}


.question-icon-06{
  bottom:15px;
  right:50%;
  opacity: 0.6;
}


.question-icon-07{
  bottom:34px;
  right:8%;
  opacity: 0.7;
}


.inner-modal .main-popup {
  width: 100% !important;
}

.inner-modal .modal-content {
  background: #000;
  padding: 24px !important;
}

.inner-modal .modal-content h3 {
  font-size: 20px !important;
  margin-bottom: 24px;
}

.inner-modal .modal-content h3 img{
  width: 40px !important;
  height: 40px !important;
}

.inner-modal .modal-content ul{
  padding: 0px 0px 0px 24px;
}

.inner-modal .modal-content ul li{
  font-size: 16px !important;
  line-height: 24px !important;
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}

}