.frame {
  width:100%;
  display: block;
  margin:0px;
  padding:0px;
}

.container-fluid {  
  background-color: #fff;
  position: relative;
  width: 100%;
  display: block;
  margin:0px;
  padding:0px;
}


.main-wrapper {
  width:1180px;
  margin:0px auto;
  text-align: center;
}


.main-header-wrapper-inside {
  background-color: #fff;
  z-index: 10;
  position: relative;
  padding: 0 15%;
}

.main-header-wrapper-inside h2{
  margin-bottom: 24px;
}

.main-heading {
  font:700 64px/80px "Uxum Grotesque-Bold", Helvetica;
  letter-spacing: 1.6px;
  text-align: center;
  display: block;
  color: #2F3640;
}

.paragraph-text {
  align-self: stretch;
  color: var(--primaryn-80);
  font:500 20px/26px "DM Sans", Helvetica;
  text-align: center;
  display: block;
  color:#666;
}


.app-download-widget{
  display: block;
  margin-top:24px;
}

.app-download-widget img {
  display: inline-block;
  margin-right:24px;
  vertical-align: middle;
}

.barcode-img{
  display: inline-block;
}

.barcode-img img{ 
  width:48px;
  height:48px;

}

.splash-banner {
  position: relative;
  display: block;
  height: 800px;
  margin-top:64px;
}

.splash-main-img,
.splash-img-01,
.splash-img-02,
.splash-img-03,
.splash-img-04,
.splash-img-05,
.splash-img-06,
.splash-img-07,
.splash-img-08,
.splash-img-09,
.splash-img-10{
  position: absolute;
}


.splash-main-img {
  width: 325px;
  height: 689px;
  top:94px;
  left:423px;
}


.splash-img-01 {
  width: 163px;
  height: 198px;
/*  background-position:-3px -382px;*/
  top:409px;
  left:0px;
}


.splash-img-02 {
  width: 165px;
  height: 200px;
/*  background-position:0px -693px;*/
  bottom:90px;
  left:0px;
}


.splash-img-03 {
  width: 166px;
  height: 193px;
/*  background-position:-188px -11px;*/
  top:38px;
  left:208px;
}


.splash-img-04 {
  width: 166px;
  height: 230px;
/*  background-position:-188px -294px;*/
  top:321px;
  left:208px;
}


.splash-img-05 {
  width: 166px;
  height: 193px;
/*  background-position:-206px -609px;*/
  bottom:10px;
  left:208px;
}



.splash-img-06 {
  width: 165px;
  height: 199px;
/*  background-position:-774px -192px;*/
  top:219px;
  right:221px;
}



.splash-img-07 {
  width: 165px;
  height: 198px;
/*  background-position:-796px -457px;*/
  top:469px;
  right:219px;
}


.splash-img-08 {
  width: 165px;
  height: 190px;
/*  background-position:-773px -723px;*/
  bottom:39px;
  right:221px;
}


.splash-img-09 {
  width: 165px;
  height: 202px;
/*  background-position:-992px -304px;*/
  bottom:473px;
  right:16px;
}



.splash-img-10 {
  width: 180px;
  height: 216px;
/*  background-position:-976px -608px;*/
  bottom:143px;
  right:9px;
}



/*
.splash-sprite {
    background-image: url('/public/img/sprite-img.png') !important;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 1180px 989px;
    font-size: 0;
    flex-shrink: 0;
  }




.splash-main-img {
  width: 325px;
  height: 689px;
  background-position:-423px -94px;
  top:94px;
  left:423px;
}


.splash-img-01 {
  width: 190px;
  height: 238px;
  background-position:-3px -382px;
  top:382px;
  left:3px;
}


.splash-img-02 {
  width: 188px;
  height: 201px;
  background-position:0px -693px;
  bottom:94px;
  left:0px;
}


.splash-img-03 {
  width: 208px;
  height: 245px;
  background-position:-188px -11px;
  top:11px;
  left:188px;
}


.splash-img-04 {
  width: 208px;
  height: 292px;
  background-position:-188px -294px;
  top:294px;
  left:188px;
}


.splash-img-05 {
  width: 207px;
  height: 243px;
  background-position:-188px -583px;
  bottom:162px;
  left:188px;
}



.splash-img-06 {
  width: 206px;
  height: 240px;
  background-position:-774px -192px;
  top:192px;
  right:200px;
}



.splash-img-07 {
  width: 165px;
  height: 200px;
  background-position:-796px -457px;
  top:457px;
  right:219px;
}


.splash-img-08 {
  width: 209px;
  height: 243px;
  background-position:-773px -723px;
  bottom:21px;
  right:198px;
}



.splash-img-09 {
  width: 165px;
  height: 200px;
  background-position:-992px -304px;
  bottom:483px;
  right:23px;
}



.splash-img-10 {
  width: 203px;
  height: 261px;
  background-position:-976px -608px;
  bottom:119px;
  right:0px;
}

/*





/* Greater than or equal to 1200px */
@media (width >= 1200px) {
  .main-wrapper {
  width:1180px;
  padding:156px 0px 0px 0px;
}

}


/* Between 992px and 1199px */
@media (992px <= width <= 1199px) {
  .main-wrapper {
  width:960px;
  padding:120px 0px 0px 0px;
}

.splash-banner {
  height: 650px;
}


.splash-main-img {
  width: 265px;
  height: 545px;
  top:84px;
  left:342px;
}


.splash-img-01 {
  width: 135px;
  height: 162px;
/*  background-position:-3px -382px;*/
  top:334px;
  left:8px;
}


.splash-img-02 {
  width: 135px;
  height: 155px;
/*  background-position:0px -693px;*/
  bottom:72px;
  left:8px;
}


.splash-img-03 {
  width: 135px;
  height: 163px;
/*  background-position:-188px -11px;*/
  top:31px;
  left:169px;
}


.splash-img-04 {
  width: 137px;
  height: 192px;
/*  background-position:-188px -294px;*/
  top:260px;
  left:168px;
}


.splash-img-05 {
  width: 135px;
  height: 165px;
/*  background-position:-206px -609px;*/
  bottom:0px;
  left:168px;
}



.splash-img-06 {
  width: 132px;
  height: 162px;
/*  background-position:-774px -192px;*/
  top:178px;
  right:181px;
}



.splash-img-07 {
  width: 135px;
  height: 164px;
/*  background-position:-796px -457px;*/
  top:382px;
  right:181px;
}


.splash-img-08 {
  width: 135px;
  height: 164px;
/*  background-position:-773px -723px;*/
  bottom:0px;
  right:174px;
}


.splash-img-09 {
  width: 134px;
  height: 161px;
/*  background-position:-992px -304px;*/
  bottom:324px;
  right:13px;
}



.splash-img-10 {
  width: 147px;
  height: 176px;
/*  background-position:-976px -608px;*/
  bottom:117px;
  right:13px;
}



  
}


/* Between 768px and 991px */
@media (768px <= width <= 991px) {
  .main-wrapper {
  width:720px;
  padding:96px 0px 96px 0px;
}


.splash-banner {
  height: 450px;
}


.splash-main-img {
  width: 198px;
  height: 408px;
  top:63px;
  left:256px;
}


.splash-img-01 {
  width: 100px;
  height: 121px;
/*  background-position:-3px -382px;*/
  top:250px;
  left:6px;
}


.splash-img-02 {
  width: 100px;
  height: 116px;
/*  background-position:0px -693px;*/
  bottom:-32px;
  left:6px;
}


.splash-img-03 {
  width: 101px;
  height: 122px;
/*  background-position:-188px -11px;*/
  top:23px;
  left:126px;
}


.splash-img-04 {
  width: 102px;
  height: 144px;
/*  background-position:-188px -294px;*/
  top:195px;
  left:126px;
}


.splash-img-05 {
  width: 115px;
  height: 123px;
/*  background-position:-206px -609px;*/
  bottom:-30px;
  left:126px;
}



.splash-img-06 {
  width: 99px;
  height: 121px;
/*  background-position:-774px -192px;*/
  top:133px;
  right:135px;
}



.splash-img-07 {
  width: 101px;
  height: 123px;
/*  background-position:-796px -457px;*/
  top:286px;
  right:134px;
}


.splash-img-08 {
  width: 101px;
  height: 123px;
/*  background-position:-773px -723px;*/
  bottom:-65px;
  right:134px;
}


.splash-img-09 {
  width: 100px;
  height: 120px;
  bottom:242px;
  right:9px;
}

.splash-img-10 {
  width: 110px;
  height: 132px;
  bottom:88px;
  right:9px;
}

.main-heading {
  font:700 48px/64px "Uxum Grotesque-Bold", Helvetica;
  letter-spacing: 1.6px;
  text-align: center;
  display: block;
}

  
}




/* Less than or equal to 700px */
@media (width <= 767px) {

.main-wrapper {
  width:100%;
  padding:84px 16px 48px 16px;
}

.main-header-wrapper-inside {
  padding: 0 0;
}


.splash-banner {
  height: 450px;
}


.splash-main-img {
  width: 180px;
  height: 370px;
  top:63px;
  margin:0px auto !important;
  left:0;
  right:0;
  z-index: 10;
}


.splash-img-01 {
  width: 80px;
  height: 98px;
  top:228px;
  left:-3%;
}


.splash-img-02 {
  width: 80px;
  height: 93px;
  bottom:63px;
  left:-3%;
}


.splash-img-03 {
  width: 80px;
  height: 98px;
  top:0px;
  left:4%;
}


.splash-img-04 {
  width: 80px;
  height: 113px;
  bottom: 0px;
  left: 87px;
  top: inherit;
}


.splash-img-05 {
  width: 80px;
  height: 98px;
  top:318px;
  left:3%;
  bottom:0;
}



.splash-img-06 {
  width: 80px;
  height: 97px;
  top:0;
  right:3%;
}



.splash-img-07 {
  width: 80px;
  height: 97px;
  top:253px;
  right:3%;
}


.splash-img-08 {
 width: 81px;
height: 98px;
  right: 0;
  bottom:10px;
}


.splash-img-09 {
  width: 80px;
  height: 98px;
  top:130px;
  right:0;
  bottom:0;
}

.splash-img-10 {
  width: 80px;
  height: 97px;
  bottom:0px;
  right:25%;
}


.main-heading {
  font:700 32px/36px "Uxum Grotesque-Bold", Helvetica;
  letter-spacing: 1.6px;
  text-align: center;
  display: block;
}

.paragraph-text {
  font-size:16px;
  line-height: 20px;
  margin-top: 8px;
}

.privacy-widget{
  padding:36px 24px !important;
}

.privacy-inner-widget{
  width:100% !important;
}

  .app-download-widget{
    text-align: center !important;
/*    z-index: 12 !important;*/
    width:100%;
  display:block !important;
  
  }

  .app-download-widget img{
    width:60%;
    margin:0px auto 16px auto;
  }

.barcode-img{
  display: none;
}

.default-link {
    display: block;
    width:100%;
    margin-top: 16px;
    margin-bottom: 64px;
    text-decoration: underline;
  }


}


/* Less than or equal to 700px */
@media (width <= 520px) { 

  .app-download-widget{
    text-align: center !important;
  }
}




.frame .concentric {
  height: 1245px;
  position: absolute;
  left: 0;
  top: 396px;  
  right: 0;
  width: 1180px;
}

.frame .overlap-group {
  border-radius: 635.94px/622.32px;
  height: 1245px;
  position: relative;
}

.frame .ellipse {
  border: 21.2px solid;
  border-color: #2c15af08;
  border-radius: 52.99px/51.86px;
  height: 104px;
  left: 583px;
  position: absolute;
  top: 570px;
  width: 106px;
}

.frame .ellipse-2 {
  border: 8.34px solid;
  border-color: #2c15af08;
  border-radius: 166.74px/163.17px;
  height: 326px;
  left: 469px;
  position: absolute;
  top: 459px;
  width: 333px;
}

.frame .ellipse-3 {
  border: 92px solid;
  border-color: #2c15af08;
  border-radius: 333.76px/326.62px;
  height: 653px;
  left: 302px;
  position: absolute;
  top: 296px;
  width: 668px;
}

.frame .ellipse-4 {
  border: 3.63px solid;
  border-color: #2c15af08;
  border-radius: 483.89px/473.52px;
  height: 947px;
  left: 152px;
  position: absolute;
  top: 149px;
  width: 968px;
}

.frame .ellipse-5 {
  border: 2.12px solid;
  border-color: #2c15af08;
  border-radius: 635.94px/622.32px;
  height: 1245px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1272px;
}





.frame .text-outer {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
  width: 784px;
}

.frame .text-inner {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}







.frame .logo-outer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.frame .image-wrapper {
  background-color: #ffffff;
  border: 1.27px solid;
  border-color: #0000000d;
  border-radius: 10.18px;
  box-shadow: 0px 5.09px 20.36px #00000029;
  height: 72px;
  overflow: hidden;
  position: relative;
  width: 72px;
}


.frame .tryit-outer {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  height: 53px;
  padding: 16px;
  position: relative;
}

.frame .tryit-inner {
  align-items: center;
  display: inline-flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.frame .tryit-inner {
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  width: fit-content;
}

.frame .group-2 {
  height: 1528px;
  left: 84px;
  position: absolute;
  top: 0;
  width: fit-content;
  /* changed */
  /* width: 1664px;  */
}

.frame .overlap-2 {
  height: 1528px;
  left: 459px;
  position: absolute;
  top: 0;
  width: 1205px;
}

.frame .overlap-3 {
  height: 1392px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1205px;
}

.frame .ellipse-6 {
  background-color: #1919191a;
  border-radius: 602.5px;
  filter: blur(747.52px);
  height: 1205px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1205px;
}

.frame .frame-wrapper-4 {
  align-items: center;
  background-color: #cfd7ed;
  border-radius: 24px;
  box-shadow: -2px 8px 24px #4a4a4a1a;
  display: flex;
  flex-direction: column;
  gap: 9.6px;
  height: 222px;
  left: 411px;
  padding: 16.01px 16.01px 0px;
  position: absolute;
  top: 711px;
  width: 184px;
}

/* card */

.frame .number-input-6 {
  color: #000000 !important;
  font-size: 6.3px !important;
  line-height: 7.6px !important;
  margin-bottom: -0.06px !important;
  margin-left: -1.53px !important;
  margin-right: -1.53px !important;
}

.frame .number-input-7 {
  height: 20.54px !important;
  margin-right: -0.01px !important;
  width: 17.79px !important;
}

.frame .number-input-8 {
  color: #000000 !important;
  font-size: 6.3px !important;
  line-height: 7.6px !important;
  margin-bottom: -0.06px !important;
  margin-left: -0.1px !important;
  margin-right: -0.1px !important;
}

.frame .number-input-9 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 20.54px;
  position: relative;
  width: 12.94px;
}

.frame .text-wrapper-7 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 12.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12.6px;
  margin-top: -0.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .actions {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 26.86px;
  position: relative;
  width: 100%;
}

.frame .mdi-video {
  height: 12.64px !important;
  position: relative !important;
  width: 12.64px !important;
}

.frame .mdi-phone-wrapper {
  align-items: flex-start;
  background-color: #26a349;
  border-radius: 39.5px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.58px;
  padding: 4.74px;
  position: relative;
}

.frame .mdi-phone {
  height: 14.22px !important;
  position: relative !important;
  width: 14.22px !important;
}

.frame .swap-me {
  height: 9.48px !important;
  position: relative !important;
  width: 9.48px !important;
}

.frame .group-4 {
  box-shadow: -2px 8px 24px #4a4a4a1a;
  height: 224px;
  left: 217px;
  position: absolute;
  top: 1147px;
  width: 184px;
}

.frame .group-wrapper {
  align-items: center;
  background-color: #34a853;
  border-radius: 24.12px;
  box-shadow: -2px 8px 24px #4a4a4a1a;
  display: flex;
  flex-direction: column;
  gap: 9.71px;
  padding: 16.18px 16.18px 0px;
  position: relative;
  width: 184px;
}

.frame .group-5 {
  height: 208.14px;
  margin-left: -16.18px;
  margin-right: -18.18px;
  position: relative;
  width: 186px;
}

.frame .overlap-group-3 {
  height: 208px;
  position: relative;
  width: 184px;
}

.frame .mask-group-2 {
  height: 207px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 184px;
}

.frame .end-to-end {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 900;
  left: 44px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.frame .group-6 {
  height: 259px;
  left: 218px;
  position: absolute;
  top: 826px;
  width: 184px;
}

.frame .overlap-4 {
  height: 308px;
  position: relative;
  width: 206px;
}

.frame .group-7 {
  height: 224px;
  left: 0;
  position: absolute;
  top: 0;
  width: 184px;
}

.frame .overlap-5 {
  border-radius: 24px;
  height: 224px;
  position: relative;
}

.frame .div-17 {
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: -2px 8px 24px #4a4a4a1a;
  height: 224px;
  left: 0;
  position: absolute;
  top: 0;
  width: 184px;
}

.frame .personalise-your {
  color: var(--primaryn-100);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 900;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
}

.frame .overlap-group-4 {
  height: 38px;
  left: 53px;
  position: absolute;
  top: 77px;
  width: 75px;
}

.frame .text-wrapper-8 {
  color: #8d8ebd;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 20px;
  letter-spacing: -0.08px;
  line-height: normal;
  position: absolute;
  top: 6px;
}

.frame .rectangle-3 {
  border: 0.53px solid;
  border-color: #8d8ebd;
  height: 34px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 74px;
}

.frame .rectangle-4 {
  background-color: #8d8ebd;
  height: 5px;
  left: 71px;
  position: absolute;
  top: 0;
  width: 5px;
}

.frame .rectangle-5 {
  background-color: #8d8ebd;
  height: 5px;
  left: 71px;
  position: absolute;
  top: 33px;
  width: 5px;
}

.frame .group-8 {
  height: 34px;
  left: 31px;
  position: absolute;
  top: 125px;
  width: 123px;
}

.frame .overlap-6 {
  height: 34px;
  position: relative;
  width: 121px;
}

.frame .rectangle-6 {
  background-color: #8d8ebd;
  border-radius: 55px;
  height: 34px;
  left: 5px;
  position: absolute;
  top: 0;
  width: 116px;
}

.frame .rectangle-7 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #8d8ebd;
  border-radius: 35px;
  height: 29px;
  left: 0;
  position: absolute;
  top: 3px;
  transform: rotate(180deg);
  width: 58px;
}

.frame .group-9 {
  height: 16px;
  left: 14px;
  position: absolute;
  top: 9px;
  width: 31px;
}

.frame .text-wrapper-9 {
  color: #8d8ebd;
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 900;
  left: 0;
  letter-spacing: -0.06px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.frame .text-wrapper-10 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 66px;
  letter-spacing: -0.06px;
  line-height: normal;
  position: absolute;
  top: 9px;
}

.frame .group-10 {
  height: 34px;
  left: 37px;
  position: absolute;
  top: 168px;
  width: 115px;
}

.frame .rectangle-9 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #8d8ebd;
  border-radius: 32px;
  height: 29px;
  left: 62px;
  position: absolute;
  top: 3px;
  transform: rotate(180deg);
  width: 51px;
}

.frame .group-11 {
  height: 16px;
  left: 15px;
  position: absolute;
  top: 9px;
  width: 41px;
}

.frame .text-wrapper-11 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.06px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.frame .text-wrapper-12 {
  color: #8d8ebd;
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 700;
  left: 72px;
  letter-spacing: -0.06px;
  line-height: normal;
  position: absolute;
  top: 9px;
}

.frame .img-3 {
  height: 119px;
  left: 87px;
  position: absolute;
  top: 189px;
  width: 119px;
}

.frame .image-4 {
  height: 743px;
  left: 540px;
  position: absolute;
  top: 589px;
  width: 361px;
}

.frame .group-26 {
  /* height: 900px;
  position: relative;
  width: 1440px; */
  height: 900px;
  position: relative;
  width: 100%; /* Makes the width responsive */
  
  box-sizing: border-box; 
}

.frame .overlap-wrapper-2 {
  background-color: #ffffff;
  height: 900px;
}

.frame .overlap-9 {
  height: 1249px;
  position: relative;
  top: -119px;
  width: 100%;
}

.frame .div-24 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 215px;
  width: 100%;
}

.frame .text-wrapper-15 {
  color: #00000080;
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 4.8px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  width: 100%;
  text-align: center;
}

/* delete end */

.frame .div-29 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 36px;
  position: relative;
  width: 100%;
}

.frame .div-30 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.frame .check-circle {
  height: 32px;
  position: relative;
  width: 32px;
}

.frame .text-wrapper-20 {
  align-self: stretch;
  color: var(--primaryn-90);
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.frame .recordings-and {
  color: var(--primaryn-90);
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.frame .div-31 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 29.97px;
  position: relative;
}

.frame .div-32 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.frame .devices-samsung-wrapper {
  box-shadow: 0px 2.91px 11.65px #00000099;
  height: 581.47px;
  position: relative;
  width: 262.27px;
}

.frame .devices-samsung {
  background-size: 100% 100%;
  height: 581px;
  position: relative;
  width: 262px;
}

.frame .div-33 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 29.97px;
  justify-content: center;
  position: relative;
}


.privacy-widget {
  background-color: #F4F4F4;
  display: block;
  padding:96px 0px;
  width: 100%;
  position: relative;
}

.privacy-inner-widget {
  position: relative;
  width:1400px;
  margin:0px auto;
}

.frame .ellipse-13 {
  background-color: #1919191a;
  border-radius: 890px;
  filter: blur(870.97px);
  height: 1780px;
  left: 110px;
  position: absolute;
  top: 0;
  width: 1780px;
}



/* Base Styles */
.frame .section {
  align-self: stretch;
  background-color: #ffffff;
  height: 50rem; 
  overflow: hidden;
  position: relative;
  width: 100%;
}

.frame .overlap-26 {
  height: 52.875rem; /* 846px */
  left: 5.25rem; /* 84px */
  position: relative;
  top: -2.75rem; /* -44px */
  width: 100%;
}

.frame .ellipse-19 {
  background-color: #1919190d;
  border-radius: 26.5625rem; /* 423px */
  filter: blur(32.677rem); /* 524.81px */
  height: 52.875rem; /* 846px */
  left: 44.4375rem; /* 711px */
  position: absolute;
  top: 0;
  width: 52.875rem; /* 846px */
}

.frame .making-a-difference {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(104.73, 120.4, 137.27) 0%,
    rgb(41, 50, 60) 52%,
    rgb(104.73, 120.4, 137.27) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Uxum Grotesque-Bold", Helvetica;
  font-size: 4rem; /* 64px */
  font-weight: 700;
  left: 17.9375rem; /* 287px */
  letter-spacing: 0.1rem; /* 1.6px */
  line-height: normal;
  position: absolute;
  text-align: center;
  text-fill-color: transparent;
  top: 8.6875rem; /* 139px */
}

.frame .slider {
  /* align-items: center; */
  display: flex;
  gap: 1.5rem; /* 24px */
  height: 26.625rem; /* 426px */
  justify-content: flex-start; /* Align items to the start */
  overflow-x: auto; /* Enable horizontal scrolling */
  position: relative; /* Changed from absolute to relative for better positioning */
  top: 20.875rem; /* 334px */
  width: 100%; /* Full width of the container */
  padding: 0 1.25rem; /* Optional padding to prevent content from touching the edges, 20px */
  box-sizing: border-box; /* Include padding in width calculation */
}

.frame .content-box {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 1.25rem; /* 20px */
  box-shadow: var(--shadow-dark-XL);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 24rem; /* 384px */
  flex-shrink: 0; /* Prevent shrinking */
}

.frame .slider.in-view {
  animation: slideEffect 10s linear infinite;
}

@keyframes slideEffect {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.frame .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1rem; /* 16px */
  padding: 1.5rem; /* 24px */
  position: relative;
  width: 100%;
}

.frame .paragraph {
  align-self: stretch;
  color: #000000;
  font-family: var(--body-XXL-font-family);
  font-size: var(--body-XXL-font-size); /* Use the `rem` conversion of your original font size */
  font-style: var(--body-XXL-font-style);
  font-weight: var(--body-XXL-font-weight);
  letter-spacing: var(--body-XXL-letter-spacing);
  line-height: var(--body-XXL-line-height);
  margin-top: -0.0625rem; /* -1px */
  position: relative;
}

.frame .user-card-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  padding: 1rem 0 0 !important; /* 16px */
  width: 100% !important;
}

.frame .user-card-2 {
  background-image: url('/public/img/user-card-2.png') !important;
  height: 4rem; /* 64px */
  width: 4rem; /* 64px */
}

.frame .user-card-3 {
  color: #000000 !important;
}

.frame .user-card-4 {
  color: #00000099 !important;
}

.frame .user-card-5 {
  background-image: url('/public/img/user-card-5@2x.png') !important;
  height: 4rem; /* 64px */
  width: 4rem; /* 64px */
}

.frame .user-card-6 {
  color: #000000 !important;
  font-family: "DM Sans", Helvetica !important;
  font-size: 1.125rem !important; /* 18px */
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.8rem !important; /* 28.8px */
}

.frame .user-card-7 {
  color: #00000099 !important;
  font-family: "DM Sans", Helvetica !important;
  font-size: 1rem !important; /* 16px */
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.4rem !important; /* 22.4px */
}

.frame .paragraph-2 {
  align-self: stretch;
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 1.5rem; /* 24px */
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.4rem; /* 38.4px */
  margin-top: -0.0625rem; /* -1px */
  position: relative;
}

.frame .user-card-8 {
  background-image: url('/public/img/user-thumb-10@2x.png') !important;
  height: 4rem; /* 64px */
  width: 4rem; /* 64px */
}

.frame .user-card-9 {
  background-image: url('/public/img/user-thumb-11@2x.png') !important;
  height: 4rem; /* 64px */
  width: 4rem; /* 64px */
}

.frame .user-card-10 {
  background-image: url('/public/img/user-thumb-12@2x.png') !important;
  height: 4rem; /* 64px */
  width: 4rem; /* 64px */
}

/* Media Queries */
@media (max-width: 1200px) {
  .frame .section {
    height: auto; /* Adjust height based on content */
  }

  .frame .overlap-26 {
    left: 2.5rem; /* 40px */
    top: -1.75rem; /* -28px */
    width: 65rem; /* 1040px */
  }

  .frame .ellipse-19 {
    left: 25rem; /* 400px */
    width: 32.875rem; /* 526px */
  }

  .frame .making-a-difference {
    font-size: 3rem; /* 48px */
    left: 11.9375rem; /* 191px */
    top: 6.25rem; /* 100px */
  }

  .frame .slider {
    height: 22.5rem; /* 360px */
  }

  .frame .content-box {
    width: 20rem; /* 320px */
  }

  .frame .paragraph {
    font-size: 1.125rem; /* 18px */
    margin-top: -0.03125rem; /* -0.5px */
  }

  .frame .user-card-2, .frame .user-card-5, .frame .user-card-8, .frame .user-card-9, .frame .user-card-10 {
    height: 3.5rem; /* 56px */
    width: 3.5rem; /* 56px */
  }

  .frame .user-card-6 {
    font-size: 1rem; /* 16px */
    line-height: 1.6rem; /* 25.6px */
  }

  .frame .user-card-7 {
    font-size: 0.875rem; /* 14px */
    line-height: 1.2rem; /* 19.2px */
  }

  .frame .paragraph-2 {
    font-size: 1.25rem; /* 20px */
    line-height: 2rem; /* 32px */
  }
}

@media (max-width: 768px) {
  .frame .section {
    height: auto;
  }

  .frame .overlap-26 {
    left: 1.5rem; /* 24px */
    top: -1rem; /* -16px */
    width: 50rem; /* 800px */
  }

  .frame .ellipse-19 {
    left: 15rem; /* 240px */
    width: 26.875rem; /* 430px */
  }

  .frame .making-a-difference {
    font-size: 2.5rem; /* 40px */
    left: 8.9375rem; /* 143px */
    top: 5.25rem; /* 84px */
  }

  .frame .slider {
    height: 18rem; /* 288px */
  }

  .frame .content-box {
    width: 18rem; /* 288px */
  }

  .frame .paragraph {
    font-size: 1rem; /* 16px */
    margin-top: 0;
  }

  .frame .user-card-2, .frame .user-card-5, .frame .user-card-8, .frame .user-card-9, .frame .user-card-10 {
    height: 3rem; /* 48px */
    width: 3rem; /* 48px */
  }

  .frame .user-card-6 {
    font-size: 0.875rem; /* 14px */
    line-height: 1.4rem; /* 22.4px */
  }

  .frame .user-card-7 {
    font-size: 0.75rem; /* 12px */
    line-height: 1.2rem; /* 19.2px */
  }

  .frame .paragraph-2 {
    font-size: 1rem; /* 16px */
    line-height: 1.6rem; /* 25.6px */
  }
}

@media (max-width: 480px) {
  .frame .section {
    height: auto;
  }

  .frame .overlap-26 {
    left: 1rem; /* 16px */
    top: -0.5rem; /* -8px */
    width: 35rem; /* 560px */
  }

  .frame .ellipse-19 {
    left: 10rem; /* 160px */
    width: 20.875rem; /* 334px */
  }

  .frame .making-a-difference {
    font-size: 2rem; /* 32px */
    left: 5.9375rem; /* 95px */
    top: 4.25rem; /* 68px */
  }

  .frame .slider {
    height: 14rem; /* 224px */
  }

  .frame .content-box {
    width: 16rem; /* 256px */
  }

  .frame .paragraph {
    font-size: 0.875rem; /* 14px */
    margin-top: 0;
  }

  .frame .user-card-2, .frame .user-card-5, .frame .user-card-8, .frame .user-card-9, .frame .user-card-10 {
    height: 2.5rem; /* 40px */
    width: 2.5rem; /* 40px */
  }

  .frame .user-card-6 {
    font-size: 0.75rem; /* 12px */
    line-height: 1.2rem; /* 19.2px */
  }

  .frame .user-card-7 {
    font-size: 0.625rem; /* 10px */
    line-height: 1.2rem; /* 19.2px */
  }

  .frame .paragraph-2 {
    font-size: 0.875rem; /* 14px */
    line-height: 1.4rem; /* 22.4px */
  }
}












