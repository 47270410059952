.user-thumb {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 100px;
  position: relative;
}

.user-thumb.s {
  background-image: 'img/size-s@2x.png';
  height: 24px;
  width: 24px;
}

.user-thumb.m {
  background-image: url('/public/img/size-m@2x.png');
  height: 48px;
  width: 48px;
}
