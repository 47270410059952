.audio-experience {
    position: relative;
    width: 100%;
  }
  
  .audio-experience .audio-container {
    position: relative;
  }
  
  .audio-experience .main-content {
    height: 206px;
    position: relative;
    display: block;
  }
  
  .audio-experience .animation-wrapper {
    height: 206px;
  }
  
  .audio-experience .animation-content {
    height: 177px;
  }
  
  @keyframes moveWave {
    0% {
      left: -494px; /* Starts off-screen */
    }
    100% {
      left: 532px; /* Ends at the original position */
    }
  }
  
  .div-89 {
    background-image: url('/public/img/wave-big.png');
    background-size: 100% 100%;
    height: 176px;
    position: absolute;
    top: 1px;
    width: 494px;
    left: 532px; /* comment this for animation */
  }

  .wave-animation {
    background-size: 100% 100%;
    height: 176px;
    position: absolute;
    top: 1px;
    width: 494px;
    right: 17%; /* comment this for animation */
  }
  
  .div-89.animate {
    animation: moveWave 5s linear forwards; /* The animation gets applied dynamically */
  }
  
  .audio-experience .animated-text {
    /* -webkit-background-clip: text !important; */
    /* -webkit-text-fill-color: transparent; */
    /* background: linear-gradient(180deg, rgb(255, 255, 255) 3.16%, rgb(19, 15, 46) 98.85%); */
    background-clip: text;
    color: transparent;
    font-family: "DM Sans", Helvetica;
    font-size: 36px;
    font-weight: 500;
    height: 52px;
    left: 0;
    letter-spacing: 0;
    line-height: 51.6px;
    position: absolute;
    text-fill-color: transparent;
    top: 60px;
    white-space: nowrap;
  }
  
  .audio-experience .icon-wrapper {
    height: 168px;
    width: 168px;
    position: absolute;
    top: 0;
    right:0;
    bottom:0;
    left: 0;
    margin:0px auto; 
    
  }
  
  .audio-experience .outer-ring {
    height: 168px;
    position: relative;
  }
  
  .audio-experience .inner-ring {
    height: 168px;
    left: 0;
    position: absolute;
    top: 0;
    width: 168px;
  }
  
  .audio-experience .overlap-group-14 {
    border-radius: 446px;
    height: 168px;
    position: relative;
  }
  
  .audio-experience .core-circle {
    border-radius: 446px;
    height: 168px;
    left: 0;
    top: 0;
    overflow: hidden;
    position: absolute;
    width: 168px;
  }
  
  .audio-experience .rectangle-25 {
    background-color: #2c15af05;
    height: 184px;
    left: -11px;
    position: relative;
    top: -13px;
    width: 184px;
  }
  
  .audio-experience .div-90 {
    background-color: #2c15af0d;
    border-radius: 347px;
    height: 131px;
    left: 19px;
    position: absolute;
    top: 19px;
    width: 131px;
  }
  
  .audio-experience .group-61 {
    background-color: #2c15af;
    border-radius: 274.18px;
    height: 100px;
    left: 34px;
    position: absolute;
    top: 34px;
    width: 100px;
  }
  
  .audio-experience .vector-14 {
    height: 69px;
    left: 52px;
    position: absolute;
    top: 50px;
    width: 65px;
  }
  
  .audio-experience .frame-wrapper-31 {

    /*align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;*/
    width: 100%;
  }
  
  .audio-experience .div-91 {
  /*  align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    justify-content: center;
    position: relative;*/
    width: 100%;
      display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap:24px;
  align-items: center;
  justify-content: center;
  }

  .audio-player  {
    flex: 0 0 auto;
  }

  .audio-experience .text-wrapper-16 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    align-self: stretch;
    background: linear-gradient(
      180deg,
      rgb(104.73, 120.4, 137.27) 0%,
      rgb(41, 50, 60) 52%,
      rgb(104.73, 120.4, 137.27) 100%
    );
    background-clip: text;
    color: transparent;
    font-family: "Uxum Grotesque-Bold", Helvetica;
    font-size: 64px;
    font-weight: 700;
    letter-spacing: 1.6px;
    line-height: normal;
    position: relative;
    text-align: center;
    text-fill-color: transparent;
  }


.audio-experience .text-wrapper-15 {
    color: #00000080;
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 4.8px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    width: 100%;
    text-align: center;
  }


  @keyframes slideFromCenterToLeft {
    0% {
      transform: translateX(50%);
      opacity: 0;
      right: 50%;
    }
    100% {
      transform: translateX(-100%);
      opacity: 1;
      right: 0%;
    }
  }
  
  /* .animated-text {
    display: none;

  }
  
  .animated-text.animate {
    display: inline-block; 
    position: absolute;
    animation: slideFromCenterToLeft 2s ease-out forwards;
  } */

  .animated-text {
    display: none;
    position: absolute;
    top: 50%; /* Vertically center the text */
    left: 50%; /* Horizontally center the text */
    right: 50%; /* Horizontally center the text */
    transform: translate(-50%, -50%); /* Center the text */
    white-space: nowrap;
    padding: 10px; /* Padding for better readability */
    border-radius: 4px; /* Optional: rounded corners */
    opacity: 0;
  }
  
  .animated-text.animate {
    display: inline-block;
    animation: slideFromCenterToLeft 5s ease-in-out forwards; /* Control the duration for smoothness */
    opacity: 2;
    color: #000;
  }



@media (1200px <= width <= 1399px) {  

.wave-animation {
  right: 10%;
}

}


@media (992px <= width <= 1199px) {  

  .wave-animation {
  right: 2%;
}


  .div-91{
  gap:12px !important;    
  align-items: start !important;
  justify-content: start !important;
   
  }

  


}


@media (768px <= width <= 991px) {  


  .div-91{
  gap:12px !important;    
  align-items: start !important;
  justify-content: start !important;
   
  }




} 




/* Less than or equal to 700px */
@media (width <= 767px) {

  .audio-experience{
    display: block;
    /*overflow: auto;*/
    /*border:1px solid #f00;*/
    margin-top: 60px;
  }

  .audio-container{
    display: block !important;
  }

  .animation-content{
    width:100% !important; 
  }

  .animation-wrapper,
  .main-content,
  .animation-content
   {
    height: 120px !important;
  }

  .wave-animation{
            height: 30px !important;
        /*right: -10%;*/
         width: auto !important; 
        /* left: 0; */
        display: block;
        /*left:20% !important;*/
        top: 24px;
  }

  .audio-experience .animated-text {
    top:10px;
    font-size: 16px;
    text-decoration: none !important;
  }

  .icon-wrapper{
    /*left:30% !important;*/
    top:-50px !important;

  }


  .div-91{
  gap:12px !important;    
  align-items: start !important;
  justify-content: start !important;
   
  }

  .audio-experience .animated-text {
    overflow: auto; 
  }


}


@media (width <= 430px) { 
      .wave-animation { 
        left:55% !important;
      }

      .audio-experience .animated-text {
        top:4px;
      }

}


  
