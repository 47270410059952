

.audio-player {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* background-color: #f9f9f9; */
    /* padding: 20px; */
    
    /* width: 300px; */
    
  
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    height: 244px;
    padding: 24px;
    background-color: #F9F9F9;
    border-radius: 24px;
    /* width: 209px; */
    /* position: relative; */
  }
  
  .progress-container {
    align-items: center;
    width: 100%;
  }
  
  .progress-bar {
    -webkit-appearance: none; /* Remove default styling */
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: #e0e0e0; /* Track color */
    outline: none;
    /* opacity: 0.7; */
    transition: opacity 0.2s;
    margin-right: 10px;
  }
  
  .progress-bar::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default thumb styling */
    appearance: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #000000; /* Thumb color */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .progress-bar::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #d0d0d0; /* Track color */
    border-radius: 5px;
  }
  
  .time {
    display: flex;
    font-size: 12px;
    align-self: flex-end;
  }
  
  .play-btn {
    background-color: #e0e0e0;
    border-radius: 16px;
    padding: 10px;
    border: none;
    z-index: 8;
    height: 64px;
    width: 64px;
  }
  
  .play-btn img {
    width: 30px;
    height: 30px;
    flex: 0 0 auto;
    position: relative;
  }


.audioWrapper {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    position: relative;
    width: 161px;
  }


.audio-text {
    align-self: stretch;
    color: #000000;
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: -1px;
    position: relative;
  }

  .label-2 {
    align-self: stretch;
    color: #ffffff;
    font-family: "DM Sans", Helvetica;
    font-size: 12px;
    font-weight: 500;
    height: 20px;
    letter-spacing: 0;
    line-height: 24.1px;
    opacity: 0.5;
    position: relative;
    text-align: right;
    white-space: nowrap;
  }
  