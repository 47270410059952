.safe-secure-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 120px;

  }


  .secure-left-panel {
    width: 50%;
/*    display: inline-block;*/
     display: grid;
  gap: 20px 50px;
    vertical-align: middle;
    padding:0px 48px;
  }


  .secure-left-panel .how-it-works-text,
  .secure-left-panel .main-heading,
  .secure-left-panel .secure-paragraph-text
  {
    text-align: left !important;
  }


   .secure-paragraph-text {
    color: #4B4B4B;
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }


.secure-left-panel .secure-btn a {
  background:#000000;
  width: 180px;
  display: block;
  margin-top: 18px;
  text-align: center;
  font:500 16px/48px "DM Sans", Helvetica;
  border-radius: 100px;
  color:#fff;
  height: 48px;
}


.secure-btn a:hover{
  color:#fff;
  background:#000;
  opacity: 0.8;
}


.secure-rt-img {
  width: 500px;
  height: 500px;
  display: inline-block;
  vertical-align: middle;
   padding:0px 48px;
   margin-top:80px;
}


.secure-rt-img img{
  width: 100%;
}




 



/* Greater than or equal to 1400px */
@media (width >= 1400px) {

.privacy-inner-widget{  
  width:1400px !important;
}

}


@media (1200px <= width <= 1399px) {  


  .privacy-inner-widget{  
  width:1180px !important;
}


}


@media (992px <= width <= 1199px) { 

.privacy-inner-widget{  
  width:960px !important;
}


.secure-left-panel {
    width: 40%;
    padding:0px 0px;
  }

  .secure-rt-img {
  width: 400px;
  height: 400px;
  padding:0px 24px;
}






}


/* Between 768px and 991px */
@media (768px <= width <= 991px) { 

  .privacy-inner-widget{  
  width:720px !important;
}


.safe-secure-container {
  width:100%;
}


  .secure-left-panel {
    width: 100%;
    padding:0px 32px;
    display: block;
  }

  .secure-left-panel .how-it-works-text,
  .secure-left-panel .main-heading,
  .secure-left-panel .secure-paragraph-text
  {
    text-align: center !important;
  }

.secure-rt-img {
  width: 100%;
  padding:0px 32px;
  display: block;
}

.secure-left-panel .secure-btn {
  margin:20px auto 20px auto;
}

.secure-rt-img .overlap-group-13 {
  margin:0px auto;

}


}



  

  /* Less than or equal to 700px */
@media (width <= 767px) {

  .safe-secure-container {
    display: block;
    padding-bottom:0px;
  }

.privacy-inner-widget{  
  width:100% !important;
}


  .secure-left-panel {
    width: 100%;
    padding:0px 0px; 
    display: grid;
    gap: 20px 50px;

  

  }

  .secure-left-panel .how-it-works-text,
  .secure-left-panel .main-heading,
  .secure-left-panel .secure-paragraph-text
  {
    text-align: center !important;
  }

.secure-rt-img {
  width: 100%;
  height: 100%;
  padding:36px 0px;
  display: block;
  margin-top:0px !important;
}

.secure-left-panel .how-it-works-text {
  margin-bottom: 0px;
}

.secure-left-panel .secure-btn {
  margin:0px auto 0px auto;
  display: block;
  text-align: center !important;
}

.secure-left-panel .secure-btn a {
  margin:0px auto;
}

.secure-paragraph-text {
  font-size: 16px !important;
  line-height: 24px; 
}



}



/* Less than or equal to 700px */
@media (width <= 520px) { 

.safe-secure-container {
    padding-bottom:0px !important;
  }





}