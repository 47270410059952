.noise-wrapper {
  background-color: #03010E;
  border-radius: 56px 56px 0px 0px;
  position: relative;
  width: 100%;
  padding:96px 0px 72px 0px;

}

.noise-main-wrapper {
  width: 100%;
  display: block;
}




.main-noise-div {
  display: block;
  padding-top:48px;
  text-align: center;
  width:1400px;
  margin:0px auto;
}


.noise-card-wrapper {
  width: 440px;
  display: inline-block;
  margin-right:24px;
  margin-bottom:24px;
}


.noise-card-inner {
  -webkit-backdrop-filter: blur(50px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(50px) brightness(100%);
  background: #1B1925;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 230px;
  overflow: hidden;
  padding: 24px;
  position: relative;
  width: 100%;
}



.noise-outer-div {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}


.noise-title-div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}


.noise-icon {
  height: 24px;
  position: relative;
  width: 30px;
}

.noise-title-text {
  color: #ffffffe6;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}


.noise-desc-div {
  height: 126px;
  margin-right: -2px;
  position: relative;
  width: 362px;
}


.noise-desc-text {
  color: #ffffff66;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 78px;
  width: 360px;
  text-align: left;
}


.noise-subtitle-div-outer {
  height: 62px;
/*  position: absolute;*/
display: block;
width: 100%;
text-align: left;
}




.noise-subtitle-index {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  display: inline-block;
/*  margin-right: 12px;*/
}

.noise-subtitle-text {
  color: #ffffffb2;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  display: inline-block;
}



/*.noise-wrapper .top-text-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 96px;
  width: 1272px;
}


.noise-wrapper .noise-big-text {
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 4.8px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.noise-wrapper .noise-small-text {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgb(255, 255, 255) 47%,
      rgba(255, 255, 255, 0.4) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Uxum Grotesque-Bold", Helvetica;
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 1.6px;
  line-height: normal;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
}

*/



/*.noise-wrapper .ellipse-12 {
  background-color: #ffffff26;
  border-radius: 399.5px;
  filter: blur(495.66px);
  height: 799px;
  left: 982px;
  position: absolute;
  top: 0;
  width: 799px;
}


*/




/* Greater than or equal to 1400px */
@media (width >= 1400px) {

.main-noise-div{  
  width:1400px !important;
}



}


@media (1200px <= width <= 1399px) { 

.noise-wrapper{
  padding:96px 0px 72px 0px !important;
} 

.main-noise-div {  
  padding-top:48px;
  width:1180px;
}

.noise-card-wrapper,
.noise-card-inner {
  width: 330px !important;
}

.noise-desc-div,
.noise-desc-text
{
  width: 100% !important;
}



}


/* Between 992px and 1199px */
@media (992px <= width <= 1199px) {


.noise-wrapper{
  padding:48px 0px 24px 0px !important;
} 


  .main-noise-div {
  width:960px;
}



.noise-card-wrapper,
.noise-card-inner {
  width: 260px !important;
}

.noise-desc-div,
.noise-desc-text
{
  width: 100% !important;
}


  
}


/* Between 768px and 991px */
@media (768px <= width <= 991px) {
  .main-noise-div {
  width:720px;
}

.noise-wrapper{
  padding: 48px 32px !important;
}


.main-heading {
  font:700 48px/64px "Uxum Grotesque-Bold", Helvetica;
  letter-spacing: 1.6px;
  text-align: center;
  display: block;
}

.noise-card-wrapper{
  width: 46% !important;
}

.noise-card-inner {
  width:100%;
}

.noise-desc-div,
.noise-desc-text
{
  width: 100% !important;
}
  
}



@media (max-width: 768px) {
  .main-noise-div {
    grid-template-columns: repeat(2, 1fr);
    padding-top: 24px !important;
  }

  .main-noise-div{
    width:100%;
    margin-bottom:0px;
  }

  .noise-wrapper{
    padding:36px 24px 36px 24px;
  }


  .noise-wrapper .noise-card-wrapper,
.noise-wrapper .noise-card-inner {
  width: 100% !important;
}

.noise-desc-div,
.noise-desc-text
{
  width: 100% !important;
}

.noise-card-wrapper{
  margin-right: 0px !important; 
}

.noise-card-inner {
  height: auto;
}

}




@media (max-width: 480px) {
  .main-noise-div{
    grid-template-columns: 1fr;
  }
}