.video-container {
  position: relative;
  width: 100%;
  height: 800px;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures video covers container without distortion */
}

.fullscreen .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.fullscreen-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2; /* Ensure the button is above the video */
}

.fullscreen-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.play-pause-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  padding: 15px;
  cursor: pointer;
  z-index: 2; /* Ensure the button is above the video */
}

.play-pause-btn img {
  width: 50px;
  height: 50px;
}


@media (width <= 767px) { 
.video-container { 
  height: 100%;

}


}
