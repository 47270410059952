/* Base Styles */
.testimonial-section {
  background-color: #EEEEEE;
  position: relative;
  width: 100%;  
  padding: 96px 0px;
}


.testimonial-section-inner{
  width: 1400px;
  margin:0px auto 48px auto;
  /*overflow:auto;*/
  /*border:4px solid #f00;*/
  /*height: 500px;*/
}


.testimonial-section .slider {
 /* align-items: center;
  display: flex;
  gap: 24px;
 
  justify-content: center; 
  overflow-x: scroll; 
  position: relative; 
  top:40px; 
  width: 100%; 
  left: 0; 
  padding-left: 100%;*/

   width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 24px;
    top:48px;
     height: auto;

     -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll; 
}

.testimonial-section .slider ::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.testimonial-section .content-box {
      align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    padding: 24px;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
    width: 385px;
    height:300px !important;
    height: auto;
    vertical-align: top;

}


.testimonial-section .content {
  display: block;
  padding: 0px !important;
  position: relative;
  width: 100%;
  vertical-align: top;
  height: 100%;
}

.testimonial-section .paragraph {
  color: #000;
  font-family: "DM Sans", Helvetica;
  font-size: 1rem; /* Use the `rem` conversion of your original font size */
  font-style: unset;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.testimonial-section .user-card-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  padding: 1rem 0 0 !important; /* 16px */
  width: 100% !important;
}

.testimonial-section .user-card-2 {
  background-image: url('/public/img/kritika.png') !important;
  height: 4rem; /* 64px */
  width: 4rem; /* 64px */
}

.testimonial-section .user-card-3 {
  color: #000000 !important;
  font-family: "DM Sans", Helvetica !important;
  margin-bottom: 4px;
}

.testimonial-section .user-card-4 {
  color: #00000099 !important;
  font-family: "DM Sans", Helvetica !important;
}

.testimonial-section .user-card-5 {
  background-image: url('/public/img/karan.png') !important;
  height: 4rem; /* 64px */
  width: 4rem; /* 64px */
}

.testimonial-section .user-card-6 {
  color: #000000 !important;
  font-family: "DM Sans", Helvetica !important;
  font-size: 1.125rem !important; /* 18px */
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.8rem !important; /* 28.8px */
}

.testimonial-section .user-card-7 {
  color: #00000099 !important;
  font-family: "DM Sans", Helvetica !important;
  font-size: 1rem !important; /* 16px */
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.4rem !important; /* 22.4px */
}

.testimonial-section .paragraph-2 {
  align-self: stretch;
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 1.5rem; /* 24px */
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.4rem; /* 38.4px */
  margin-top: -0.0625rem; /* -1px */
  position: relative;
}

.testimonial-section .user-card-8 {
  background-image: url('/public/img/abhinav-gupta.png') !important;
  height: 4rem; /* 64px */
  width: 4rem; /* 64px */
}

.testimonial-section .user-card-9 {
  background-image: url('/public/img/prasoon-sharma.png') !important;
  height: 4rem; /* 64px */
  width: 4rem; /* 64px */
}

.testimonial-section .user-card-10 {
  background-image: url('/public/img/user-thumb-12@2x.png') !important;
  height: 4rem; /* 64px */
  width: 4rem; /* 64px */
}




/* Greater than or equal to 1200px */
@media (width >= 1400px) {
  .testimonial-section-inner {  
  width:1400px !important;  
}

}



@media (1200px <= width <= 1399px) { 
    .testimonial-section-inner {   
      width:1180px;
  }
}


@media (992px <= width <= 1199px) {
  .testimonial-section-inner {  
  width:960px !important;
  
}
}


@media (768px <= width <= 991px) {
  .testimonial-section-inner {  
  width:720px !important;
  height: auto !important;
  /*padding-bottom: 36px;*/
  
}

}


@media (width <= 767px) {

  .testimonial-section{
    padding:40px 24px !important;
  }

  .testimonial-section-inner {  
  width:100% !important;
  height: auto !important;
  margin-bottom: 48px;
  /*padding-bottom: 36px;*/
  
}

.testimonial-section .slider {
  top:24px;
}

  }


@media (width <= 480px) {
.testimonial-section .content-box {
  width: 300px;
}

}





