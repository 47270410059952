.quicklink-wrapper{
    border-radius: 56px 56px 0px 0px;
    background: #191919;
    position: relative;
    width: 100%;
    padding:0px 0;
    margin:0px auto;
    /*overflow: auto;
    border: 1px solid #f00;*/
}



.quicklink-inner {
  position: relative;
  width:100%;
  /*overflow: auto;
  border: 1px solid #ff0;*/
  height: 500px;
}



.quicklink-automate-div {
  height: 290px;
  position: relative;
  padding: 96px 84px;
  width: 50%;
}


.quicklink-automate-div h2{
  text-align: left;
  margin-bottom: 40px;
}


.quicklink-social-container {
  align-items: center;
  border-radius: 16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}



.quicklink-big-phone-div {
  height: auto;
  width: 564px;
  position: absolute;
  top: 0;
  right: 0;
}


.quicklink-big-phone-div img{ 
width: 100%;
}



/*logo and navigation*/

.quicklink-try-container {
  width: 100%;
  display: block;
  /*overflow: auto;
  border: 1px solid #f00;*/
  padding: 96px 84px;
  position: relative;
}



.quicklink-try-wrapper {
  height: auto;
  position: relative;
  width: 200px;
  height: auto;
  /*overflow: auto;
  border: 1px solid #f00;*/
  display: inline-block;
}


.quicklink-action-wrapper {
  position: absolute;
  width: 30%;
  /*height: 500px;*/
  display: inline-block;
  /*overflow: auto;
  border: 1px solid #f00;*/
  right: 0px;
}



.quicklink-try-div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.quicklink-try-div-inner {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 183px;
}

.vector-15 {
  height: 32px;
  position: relative;
  width: 32px;
}

.quicklink-samvaad-text {
  color: #ffffff;
  font-family: "Uxum Grotesque-Ultra", Helvetica;
  font-size: 27px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.quicklink-mission-text {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.quicklink-try-text-div {
  align-items: center;
  background-color: #ffffff;
  border-radius: 62px;
  display: flex;
  height: 48px;
  overflow: hidden;
  position: relative;
  width: 180px;
  margin-top:20px;
}

.quicklink-try-text {
  align-self: stretch;
  color: #191919;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  position: relative;
  text-align: center;
  display: block;
  width: 100%;
}


.svg-container {
    display: block;
    margin: 0px auto;
    text-align: center;
    padding: 0px 5%;
}

.svg-container img{
  width: 100%;
}




@media (1200px <= width <= 1399px) { 

  .quicklink-action-wrapper {
    width: 40%;
  }

}


@media (992px <= width <= 1199px) { 
 .quicklink-action-wrapper {
    width: 40%;
  }


.quicklink-automate-div .main-heading-white {
  font-size:40px;
  line-height: 120%;
}

}



@media (768px <= width <= 991px) { 

  .quicklink-automate-div .main-heading-white {
  font-size:40px;
  line-height: 120%;
}

.quicklink-automate-div {
  padding:40px 24px;
}

.quicklink-big-phone-div {
  width: 300px;
  right: 0%;
}


.quicklink-try-container {
  padding:36px 24px 56px 24px;
}

.quicklink-action-wrapper {
    width: 55%;
  }


}


@media (width <= 767px) { 

  .quicklink-inner {
    height: auto;
  }

  .quicklink-automate-div {
    height: auto;
    width: 100%;
    padding:36px 24px;
    text-align: center;
  }

  .quicklink-big-phone-div {
    display: block;
    position: relative !important;
    width: 100% !important;
  }

  .quicklink-big-phone-div img{
    width: 100%;
    height: 100%;
  }

  .image-12 {
    display: none;
  }

  .quicklink-try-container {
    padding:36px 24px;
  }

  .quicklink-try-wrapper {
    width: 100%;
  }

  .quicklink-action-wrapper {
    position: relative;
    display: block;
    width: 100%;
    margin:36px 0px 68px 0px;

  }


  .quicklink-action-div {
    width: 50% !important;
  }

  .socials-section {
    width: 50%;
    vertical-align: top;
  }

}


@media (width <= 480px) {  

  .socials-section {
    width: 100%;
    margin-top: 36px;
  }




}















.ellipse-20 {
  background-color: #ffffff26;
  border-radius: 651.6px;
  filter: blur(808.43px);
  height: 1303px;
  left: 550px;
  position: absolute;
  top: 0;
  width: 1303px;
}

.text-wrapper-56 {
  color: #ffffff;
  font-family: "Uxum Grotesque-Medium", Helvetica;
  font-size: 242px;
  font-weight: 500;
  left: 5px;
  letter-spacing: 6.05px;
  line-height: normal;
  text-align: center;
  white-space: nowrap;
  padding-bottom: 0px;
}



.automate-calls-with {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.8) 47%,
      rgba(255, 255, 255, 0.4) 100%);
  background-clip: text;
  color: transparent;
  font-family: "Uxum Grotesque-Bold", Helvetica;
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 1.6px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-fill-color: transparent;
  width: fit-content;
}


.image-12 {
  height: 119.11px;
  object-fit: cover;
  position: relative;
  width: 119.11px;
}

.quicklink-social-div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
}

.icon-instance-node-3 {
  height: 48px !important;
  position: relative !important;
  width: 160px !important;
}



.quicklink-action-div {
  position: relative;
  width: 200px;
  display:inline-block;
}

.socials-section {
  position: relative;
  width: 200px;
  display:inline-block;
}

.socials-list {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap:20px;
  position: relative;
  width: 100%;
}

.social-icon {
  height: 16.05px;
  position: relative;
  width: 16.05px;
}


.quicklinks-title {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  margin-bottom: 20px;
}

.quicklink-list {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12.03px;
  position: relative;
}

.quicklink-item {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8.02px;
  position: relative;
  width: 100%;
}

.quicklink-text {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.quicklink-text img{
  margin-left: 4px;
  vertical-align: middle;
}

.quicklink-link {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12.03px;
  position: relative;
  width: 100%;
}





