.header {
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 13;
  display: flex;
  justify-content: center;
  height: 64px;
  /*overflow: auto;*/
  /*border:1px solid #f00;*/
  padding:16px 32px;
/*  border-bottom:1px solid #EFEEF6;*/
}

/*.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  width: 65%;
}*/


.logo {
  width: 166px;
}

/* Navigation Links */
.nav {
  /*display: flex;
  gap: 20px;*/
  
      width:100%;
    text-align: center;
    display: inline-block;

}

.nav-link {
  text-decoration: none;
  color: black;
  font-size: 16px;
  transition: color 0.3s;
  font:500 16px "DM Sans", sans-serif;
  color: #000;
  display: inline-block;
    padding-bottom:2px;
    background-position: right bottom;
    background-size: 300% 2px;
    background-repeat: no-repeat;
    margin: 4px 48px 0px 0px;
}

.nav-link:last-child{
  margin-right:0px !important;
}

.nav-link:hover {
  transition: background-position 0.5s;
  background-image: linear-gradient(to right, #000 33%,#0000 33% 66%,#000 66%);
}

/* Burger Icon for Mobile */
.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.burger-icon {
  width: 25px;
  height: 2px;
  background-color: black;
  position: relative;
}

.burger-icon::before,
.burger-icon::after {
  content: "";
  width: 25px;
  height: 2px;
  background-color: black;
  position: absolute;
  left: 0;
  transition: all 0.3s ease;
}

.burger-icon::before {
  top: -8px;
}

.burger-icon::after {
  top: 8px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header {
    width: 100%;
    /*border-bottom:1px solid #f00;*/
    display: block;    
  }


  .logo{
    display: inline-block;
  }

  .nav {
      position: absolute;
      top: 64px;
      right: 0;
      background-color: white;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;
      display: block;
      /*overflow:auto;*/
      /*border:1px solid #f00;*/
      text-align: right;
      /*background:#EFEEF6;*/
      background: #010101;
      border-radius: 0px 0px 32px 32px;
      backdrop-filter: blur(30px);
  }

  .nav.open {
      max-height: 300px;

  }


  .header-mweb-relative  {
    position: fixed;
    width: 40px;
    height: 40px;
    right: 10px;
    top:14px;
    /*overflow: auto;
    border: 1px solid #f00;*/
  }



  .menu-toggle {
      right:0px;
      top:30px;
      width: 40px;
      height: 40px;
      z-index:9;
      display: flex;
      align-items: center;
      justify-content: center;

  }

  .nav-link {
      /*padding: 1rem;*/
      font-size: 18px;
      text-align: center;
      display: block;
      padding:16px 24px 16px 24px;
      text-align: left;
      margin-right:0px !important;
      color: #fff;
  }

  .nav-link:hover{
    background-image: none !important;
  }


}
