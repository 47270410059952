.contact-us {
  padding-bottom:0px;
}

.faq-tabs {
  margin: 64px 40px 0px 40px;
  /*overflow:auto;
  border:1px solid #f00;*/
}

.faq-tabs ul {
  margin: 0px;
  padding: 0px;
  border-bottom: 1px solid #E5E5E5;
  display: block;
  width: 100%;
  text-align: left;
}

.faq-tabs ul li {
  display: inline-block;
  padding: 12px 16px;
  font: 500 20px/26px "DM Sans", Helvetica;
  color: #7D7D7D;
}

.faq-tabs ul li:hover{
  cursor: pointer;
}


.faq-tabs ul li.active {
  color: #010101;
  border-bottom: 2px solid #010101;
}


.Tab-one {
  display: block;
  margin-top: 16px;
}

.tab-content {
  display: block;
  overflow: auto;
  border-bottom: 1px solid #DEDEDE;
  text-align: left;
  /* padding-bottom: 32px; */
}

.tab-content .faq-question {
  font: 600 20px/26px "DM Sans", Helvetica;
  padding: 32px;
  color: #010101;
  position: relative;
  cursor: pointer;
}

.faq-question .icon-down {
  position: absolute;
  right: 16px;
  /* top: 32px; */
}

.faq-answer {
  font: 400 20px/30px "DM Sans", Helvetica;
  padding: 0px 32px;
  color: #010101;
  padding-bottom: 32px;
}


.contact-us .data-widget {
  padding: 200px 24%;

}

.contact-us .data-widget p {
  font: 400 20px/30px "DM Sans", Helvetica;
  padding: 32px;
  color: #fff;
  text-align: center;
}

.contact-cta {
  margin: 0px auto;
  display: block;
  text-align: center;
}

.contact-cta .white-btn {
  color: #000;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  background-color: #fff;
  border-radius: 62px;
  height: 48px;
  display: inline-block;
  padding: 0px 24px;
  cursor: pointer;
}

/*modal*/
.contact-modal .main-popup {
  height: 400px;
  border-radius: 24px;
}

.contact-modal .main-popup,
.contact-modal .modal-content {
  width: 600px !important;
}

.contact-modal .modal-content {
  padding: 24px 40px;
  height: 100%;
  overflow-x: scroll;
  text-align: left;
}

.contact-modal .modal-content h3 {
  font: 600 24px/36px "DM Sans", Helvetica;
  text-align: left;
  margin-bottom: 32px;
}


.contact-modal .modal-content label,
.contact-modal .modal-content input{
   display: block;
   text-align: left;
   width: 100%;

}


.contact-modal .modal-content label{
  font: 400 20px/26px "DM Sans", Helvetica;
  color:#1A1A1A;
  margin-bottom: 8px;
}


.contact-modal .modal-content input {
  font: 400 20px/26px "DM Sans", Helvetica;
  color: #AFAFAF;
  margin-bottom: 20px;
  background: #00000005;
  border:none;
  padding:12px 16px;
  border-radius: 8px;

}


.contact-modal .modal-content textarea {
  font: 400 20px/26px "DM Sans", Helvetica;
  color: #AFAFAF;
  background: #00000005;
  border:none;
  padding:12px 16px;
  width:100%;
  border-radius: 8px;

}


.contact-modal .modal-content ::-webkit-input-placeholder { /* Chrome */
  color: grey;
  transition: opacity 250ms ease-in-out;
  font-size: 16px;
}

.contact-modal .modal-content:focus::-webkit-input-placeholder {
  opacity: 0.5;
}


.contact-modal .modal-content .helper-text {
  display:block;
  text-align: left;
}

.contact-modal .modal-content .helper-text a{
  text-decoration: underline;
  color: #000;
}

.contact-modal .modal-content .submit-btn{
      background: #000000;
    width: 180px;
/*    display: block;*/
    margin-top: 18px;
    text-align: center;
    font: 500 16px/44px "DM Sans", Helvetica;
    border-radius: 100px;
    color: #fff;
    height: 48px;
    cursor: pointer;
    border:none;
    margin-bottom: 12px;
}


@media (992px <= width <= 1199px) { 
  .main-popup{
    margin-top: 10%;
  }

}


@media (768px <=width <=991px) { 

   .main-popup{
    margin-top: 15%;
  }

}



@media (max-width: 768px) {
  .faq-tabs {
    padding: 0px;
    margin: 24px 0px 0px 0px;
  }

  .contact-us .data-widget {
  padding: 160px 10%;

}

  .faq-tabs ul {
    display: flex;
    flex-wrap: nowrap;
    /*align-items:start;
  justify-content: start;
  */
    overflow-x: auto;
  }

  .faq-tabs ul li {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  }

  .Tab-one {
    margin-top: 32px;
  }


  .tab-content {
    padding: 16px 0px;
  }


  .tab-content .faq-question {
    padding: 0px 48px 0px 0px;
    font-size: 18px;
  }

  .faq-question .icon-down {
    right: 0px;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .tab-content .faq-answer {
    font-size: 16px;
    padding: 0px;
    margin-top: 16px;
  }

  .contact-modal .main-popup{
    width: 100% !important;
    height: 550px;
  }

  .contact-modal .modal-content {
    width: 100% !important;
    padding: 16px 16px;
  }



}