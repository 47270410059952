* {
  box-sizing: border-box;
}

body {
  background: #fff !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
}


.stacked-main-wrapper {
background: #FDFDFD;
position: relative;
width: 100%;
padding: 96px 0px;
}

.stacked-card {
	position: sticky;
	top: 96px;
  width:1400px;
  margin:0px auto;
  /*padding:160px 0px 96px 0px;*/
  text-align: center;
  height: auto;
  /*padding-bottom: 120px;*/
  
}

.stacked-card-inner {
  /*display: flex;
  overflow: hidden;
  transform-origin: center top;
  justify-content: center;
  */background: #EFEEF6;
  border-radius: 32px;
  padding:0px;
/*  height: 548px;*/
  margin:48px 0px;
  display: block;
}



.how-it-works-text {
  color: #7D7D7D;
  font:700 20px/26px "DM Sans", Helvetica;
  letter-spacing: 2px;
  position: relative; 
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
  text-transform: uppercase;
  }


.stacked-heading-widget {
  display: inline-block;
  width:40%;
  padding:120px 48px 0px 48px;
  vertical-align: top;
  height: 100%;
}

.stacked-heading-widget h3{
  color: #1A1A1A;
  font:700 40px/56px "DM Sans", Helvetica;
  position: relative; 
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
}

.stacked-heading-widget span,
.stacked-heading-widget number
{
  color: #4D4D4D;
  font:700 18px/27px "DM Sans", Helvetica;
  position: relative; 
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
  display: block;
}


.stacked-heading-widget span {
  display: flex !important;
}

.stacked-heading-widget span i{
height: 100%;
/*overflow:auto;
border:2px solid #f00;
*/display: block;
width: 20px !importnat;
margin:8px 8px 0px 0px;
vertical-align: middle;
}

.stacked-heading-widget span p{

  display: block;
}


.tickicon{
  width: 20px;
  height: 20px;
}



.stacked-heading-widget span {
  font:500 20px/26px "DM Sans", Helvetica;
  text-align: left;
  vertical-align: text-top;
  display: block;
}

.stacked-image-widget img{
  width:100%;
}

.stacked-card:last-child{
  margin-bottom:160px !important;
}



.stacked-heading-widget number {
  color: #D0CCE9 !important;
  font:700 40px/60px "DM Sans", Helvetica !important;
  margin-top: 48px !important;
}



.stacked-image-widget{
  display: inline-block;
  width:48%;
  padding:0px;
  vertical-align: top;
  height: 100%;
}


/*old css*/


.card__image-container {
  display: flex;
  width: 40%;
  flex-shrink: 0;
}

.card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}

.card__content {
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  background-color: red;
  height: 70vh;
  width: 100%;
}

.card__title {
  padding: 0;
  margin: 0;
  font-size: 60px;
  font-weight: 600;
  color: #16263a;
}

.card__description {
  line-height: 1.4;
  font-size: 24px;
  color: #16263a;
}

.space {
  height: 90vh;
}

.space--small {
  height: 40vh;
}

.div-24-stack {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    left: 0;
    position: absolute;
    top: 215px;
    width: 100%;
  }


/*old css end*/



@media (width >= 1400px) {

.stacked-card{  
  width:1400px !important;

}

}




@media (1200px <= width <= 1399px) { 
.stacked-card {
  top: 96px;
  width:1180px;
}


.stacked-heading-widget {
  padding:120px 48px 0px 48px;
}

}


/* Between 992px and 1199px */
@media (992px <= width <= 1199px) {

.stacked-card {
  top: 80px;
  width:960px;
}


.stacked-heading-widget {
  width: 50%;
  padding:48px 48px 0px 48px;
}

  
}


/* Between 768px and 991px */
@media (768px <= width <= 991px) {
.stacked-card {
  top: 72px;
  width:720px;
}

.stacked-heading-widget {
  width: 50%;
  padding:48px 24px 0px 24px;
}

.stacked-image-widget {
  width: 50%;
}

.stacked-heading-widget h3{
  font-size:24px;
  line-height: 30px;
}


.stacked-heading-widget span{
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}


.stacked-heading-widget number {
  margin-top:24px !important;
  margin-bottom: 0px;
}




.how-it-works-text { 
  font-size: 16px;
  line-height: 20px;
}



}


/* Less than or equal to 700px */
@media (width <= 767px) {

.stacked-card {
  width:100%;
}

.stacked-main-wrapper {
  padding: 48px 24px;
}

.stacked-card-inner{
  border-radius: 24px;
  margin:24px 0px;

}

.stacked-heading-widget {
  width: 100%;
  padding:24px 24px 0px 24px;
}

.stacked-image-widget {
  width: 100%;
}


.stacked-heading-widget number {
  display: none;
}

.stacked-heading-widget span i{
width: 16px !importnat;
margin:4px 8px 0px 0px;
}

.tickicon {
  width: 16px;
  height: 16px;
}


.stacked-card:last-child{
  margin-bottom:100px !important;
}



.how-it-works-text {
  font-size:16px;
  line-height:20px;
}

.stacked-heading-widget h3{
  font-size:24px;
  line-height: 32px;
  font-weight: 600;
}


.stacked-heading-widget span,
.stacked-heading-widget number
{
  
  font:500 16px/20px "DM Sans", Helvetica;
}

.stacked-heading-widget number {
  color: #D0CCE9 !important;
  font:700 40px/60px "DM Sans", Helvetica !important;
  margin-top: 48px !important;
}



}

  




