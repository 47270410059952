.benefits {
    background-color: #03010E;
    border-radius:56px 56px 0px 0px;
    position: relative;
    padding:96px 0px 96px 0px;
    width: 100%;
    
  }
  
  .benefits-wrapper {
    position: relative;
    margin:0px auto;
    width: 1400px;    
    height: 200px;
    position: relative;
    

  }

  .benefit-left-box{
    display: inline-block;
    padding:20px 40px;
    width: 33%;
    vertical-align: top;
    height: 100%;
  }


.scroll-box {
  width: 66%;
  display: inline-block;
  height: 200px;
  overflow-y: scroll;
  /*border:1px solid #f00;*/
}



.scroll-box::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #494851;
}

.scroll-box::-webkit-scrollbar
{
  width: 2px;
  background-color: #fff;
}

.scroll-box::-webkit-scrollbar-thumb
{
  background-color: #fff;
}

  .benefit-md-box{
    display: inline-block;
    padding:20px 40px;
    width: 50%;
    vertical-align: top;
    height: 100%;
    margin-bottom: 60px;
    
  }


.benefit-left-box p,
.benefit-md-box p,
.benefit-rt-box p

{
  text-align: left !important;
  color: #FFFFFF99;
  font: 700 20px/30px "DM Sans", Helvetica;
}

.benefit-main-heading{
    color:#fff;
    font: 700 40px / 60px "Uxum Grotesque-Bold", Helvetica;
    letter-spacing: 1.6px;
    text-align: left;
    display: block;
}


.benefit-main-heading-white{
    color:#fff;
    font: 700 40px / 60px "Uxum Grotesque-Bold", Helvetica;
    letter-spacing: 1.6px;
    text-align: left;
    display: block;
}



.benefits .line-container {
    background-color: #ffffff33;
    height: 2px; 
    position: absolute;
    transform: rotate(-90deg);
    width: 7.5rem; /* 120px in rem */
    right:-40px;
    top:50px;
  }
  
  .benefits .line {
    background-color: #ffffff;
    height: 2px;
    width: 3.75rem;
  }




/* Greater than or equal to 1200px */
@media (width >= 1400px) {
  .benefits-wrapper {  
  width:1400px !important;
}

}


@media (1200px <= width <= 1399px) { 
    .benefits-wrapper {  
      width:1180px;
  }
}


@media (992px <= width <= 1199px) {
  .benefits-wrapper {  
  width:960px !important;
  
}
}


@media (768px <= width <= 991px) {
  .benefits-wrapper {  
  width:100% !important;
  height: auto !important;
  padding-bottom: 36px;
  
}

.scroll-box {
  height: auto;
}

.scroll-box {
  height: auto;
  overflow-y:inherit;
}



.scroll-box,
.benefit-left-box,
.benefit-md-box
 {
  width:100% !important;
  height: auto;
}

.benefits-wrapper .feature-text,
.benefits-wrapper .benefit-main-heading
{
  text-align: center !important;

}

.benefit-md-box .benefit-main-heading-white,
.benefit-md-box p
{
  text-align: center !important;

}

.benefit-md-box {
  margin-bottom: 20px;
}



}


@media (width <= 767px) {

  .benefits {
    padding:48px 24px 0px 24px;
  }


 .benefits-wrapper {  
  width:100% !important;
  height: auto !important;
  padding-bottom: 36px;
  
}

.scroll-box {
  height: auto;
  overflow-y:inherit;
}


.scroll-box,
.benefit-left-box,
.benefit-md-box
 {
  width:100% !important;
  height: auto;
}

.benefits-wrapper .feature-text,
.benefits-wrapper .benefit-main-heading
{
  text-align: center !important;

}

.benefit-md-box .benefit-main-heading-white,
.benefit-md-box p
{
  text-align: center !important;

}

.benefit-md-box {
  margin-bottom: 20px;
}

.benefit-main-heading,
.benefit-main-heading-white{
  font-size:24px;
  line-height: 100%
}




}

  