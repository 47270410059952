.flow-wrapper {
    background: #FDFDFD;
    position: relative;
    width: 100%;
    padding:96px 0px;
  }


.flow-highlight-img {
    margin:24px auto;
    text-align: center;
    width:1300px;
    /*border: 1px solid #f00 !important;*/
 }


.visible-desktop{
  display: block;
  /*width:100%;*/
  margin:0px auto;
}

.visible-mweb{
  display: none;
}



/* Greater than or equal to 1200px */
@media (width >= 1400px) {
  .flow-highlight-img {  
  width:1300px;

}
}

@media (1400px <= width <= 1920px) {  

  .visible-mweb{
  display: none;
}

.visible-desktop{
  display: block;
  margin:0px auto;
  width: 100%;
}



}


@media (1200px <= width <= 1399px) {  

  .flow-highlight-img {  
  width:1180px !important;
  }

.visible-desktop{
  width:100% !important;
}


}



@media (992px <= width <= 1199px) { 

.flow-highlight-img {  
  width:960px;
}

.visible-desktop{
  width:100%;
}


}


@media (768px <= width <= 991px) { 
.flow-highlight-img {  
  width:720px;
}

.visible-desktop{
  width:100%;
}

.flow-wrapper{
  padding: 36px 24px;
}


}




@media (width <= 767px) {


.flow-wrapper{
  padding: 36px 24px;
}

.flow-wrapper h2{
  font-size: 26px !important;
  
}


  .flow-highlight-img {  
  width:100%;
}

.visible-desktop{
  display: none;
}


.visible-mweb{
  display: block;
  width: 100%;
}



}










