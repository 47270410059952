/* card Right one */
.card-right-one {
  align-items: center;
  background-color: #cfd7ed;
  border-radius: 24px;
  box-shadow: -2px 8px 24px #4a4a4a1a;
  display: flex;
  flex-direction: column;
  gap: 9.6px;
  height: 222px;
  left: 411px;
  padding: 16.01px 16.01px 0px;
  position: absolute;
  top: 711px;
  width: 184px;
}

.card-right-one .card-div {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 40.82px;
  height: 206px;
  margin-bottom: -0.01px;
  margin-left: -0.01px;
  margin-right: -0.01px;
  position: relative;
}

.card-right-one .div-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.card-right-one .automate-text {
  color: #333333;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.8px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.card-right-one .phone-outer {
  height: 243px;
  left: 2px;
  position: relative;
  width: 149px;
}

.card-right-one .phone-div {
  align-items: center;
  background-color: #e9edf7;
  border-radius: 53px;
  display: flex;
  gap: 9.6px;
  justify-content: center;
  left: 17px;
  overflow: hidden;
  padding: 11.2px;
  position: absolute;
  top: 33px;
  width: 116px;
}

.card-right-one .img {
  flex: 0 0 auto;
  margin-left: -0.66px;
  position: relative;
}

.card-right-one .forward-calls {
  color: #2c15af;
  font-family: "DM Sans", Helvetica;
  font-size: 11.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -0.66px;
  margin-top: -0.77px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.card-right-one .rectangle {
  border: 2px solid;
  border-color: #99a1be;
  border-radius: 58px;
  height: 54px;
  left: 8px;
  opacity: 0.2;
  position: absolute;
  top: 25px;
  width: 133px;
}

.card-right-one .rectangle-2 {
  border: 2px solid;
  border-color: #99a1be;
  border-radius: 58px;
  height: 69px;
  left: 0;
  opacity: 0.25;
  position: absolute;
  top: 18px;
  width: 149px;
}



.card-right-two {
  align-items: center;
  background-color: #e5cfca;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 222px;
  left: 411px;
  overflow: hidden;
  padding: 16.01px 16.01px 0px;
  position: absolute;
  top: 991px;
  width: 184px;
}

.card-right-two .check-call-summaries-wrapper {
  align-items: flex-start;
  display: inline-flex;
  gap: 6.42px;
  height: 33.71px;
  position: relative;
}

.card-right-two .check-call-summaries {
  color: var(--primaryn-100);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -7.49px;
  margin-top: -0.8px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.card-right-two .img-wrapper {
  align-self: stretch;
  background-color: #e3e3e3;
  border-radius: 15.16px;
  height: 174.74px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.card-right-two .image-2 {
  height: 154px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 152px;
}


.card-right-four {
  height: 222px;
  left: 1172px;
  position: absolute;
  top: 822px;
  width: 184px;
}

.card-right-four .card-wrapper {
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  display: inline-flex;
  flex-direction: column;
  gap: 9.6px;
  height: 222px;
  padding: 16.01px 16.01px 0px;
  position: relative;
}

.card-right-four .card-div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 189px;
  position: relative;
  width: 100%;
}

.card-right-four .stay-informed-with {
  color: #333333;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 151.99px;
}

.card-right-four .card-four-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: #e5e5f04c;
  border-radius: 9.63px;
  display: flex;
  flex-direction: column;
  height: 62px;
  justify-content: center;
  padding: 6.42px 8px;
  position: relative;
  width: 100%;
}

.card-right-four .img-4 {
  flex: 0 0 auto;
  margin-bottom: -19px;
  position: relative;
}


.card-right-five {
  height: 222px;
  left: 629px;
  position: absolute;
  top: 1170px;
  width: 184px;
}



.card-right-five .div-main {
  align-items: center;
  background-color: #decece;
  border-radius: 24px;
  box-shadow: -2px 8px 24px #4a4a4a1a;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 222px;
  overflow: hidden;
  padding: 16.01px 16.01px 0px;
  position: relative;
  width: 184px;
}

.card-right-five .transcripts-of-every-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.42px;
  position: relative;
}


/* ABC */

.card-left-one {
  height: 224px;
  left: 218px;
  position: absolute;
  top: 600px;
  width: 184px;
  }
  
  .card-left-one .div-18 {
  align-items: center;
  background-color: #eb9481;
  border-radius: 24px;
  box-shadow: -2px 8px 24px #4a4a4a1a;
  display: flex;
  flex-direction: column;
  gap: 9.71px;
  height: 224px;
  overflow: hidden;
  padding: 16.18px 16.18px 0px;
  position: relative;
  width: 184px;
  }
  
  .card-left-one .p {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.81px;
  position: relative;
  text-align: center;
  width: 127.79px;
  }
  
  .card-left-one .group-13 {
  height: 294px;
  left: -46px;
  position: absolute;
  top: 85px;
  width: 279px;
  }
  
  .card-left-one .overlap-group-5 {
  height: 294px;
  position: relative;
  }
  
  .card-left-one .ellipse-7 {
  background-color: #ffffff33;
  border-radius: 139.4px;
  height: 279px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 279px;
  }
  
  .card-left-one .group-14 {
  height: 32px;
  left: 122px;
  position: absolute;
  top: 0;
  width: 32px;
  }
  
  .card-left-one .img-left-4 {
  height: 32px;
  left: 176px;
  position: absolute;
  top: 9px;
  width: 32px;
  }
  
  .card-left-one .img-5 {
  height: 32px;
  left: 69px;
  position: absolute;
  top: 9px;
  width: 32px;
  }
  
  .card-left-one .div-19 {
  align-items: center;
  border: 1.3px dashed;
  border-color: #ffffff;
  border-radius: 7.79px;
  display: flex;
  gap: 4px;
  justify-content: center;
  left: 45px;
  padding: 5.19px;
  position: absolute;
  top: 152px;
  width: 101px;
  }
  
  .card-left-one .prohibit {
  position: relative;
  width: 20.7px;
  }
  
  .card-left-one .text-wrapper-13 {
    color: #ffffff;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.3px;
    position: relative;
    text-align: center;
    width: fit-content;
  }


  /* ABC */


/* common */
.text-wrapper-common {
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.8px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.card-right-five .mask-group {
  height: 139.99px;
  margin-left: -16.01px;
  margin-right: -16.01px;
  position: relative;
  width: 184px;
}
  








.card-container {
  height: 224px;
  left: 218px;
  position: absolute;
  top: 510px;
  width: 184px;
}

.card-title {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.81px;
  position: relative;
  text-align: center;
  width: 127.79px;
}

.icon-container {
  height: 294px;
  left: -46px;
  position: absolute;
  top: 85px;
  width: 279px;
}

.icon-background {
  height: 294px;
  position: relative;
}

.icon-overlay {
  background-color: #ffffff33;
  border-radius: 139.4px;
  height: 279px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 279px;
}

.icon {
  height: 32px;
  left: 122px;
  position: absolute;
  top: 0;
  width: 32px;
}

.icon-small-1 {
  height: 32px;
  left: 176px;
  position: absolute;
  top: 9px;
  width: 32px;
}

.icon-small-2 {
  height: 32px;
  left: 69px;
  position: absolute;
  top: 9px;
  width: 32px;
}

.status-container {
  align-items: center;
  border: 1.3px dashed;
  border-color: #ffffff;
  border-radius: 7.79px;
  display: flex;
  gap: 4px;
  justify-content: center;
  left: 45px;
  padding: 5.19px;
  position: absolute;
  top: 152px;
  width: 101px;
}

.prohibit-icon {
  position: relative;
  width: 20.7px;
}

.status-text {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.3px;
  position: relative;
  text-align: center;
  width: fit-content;
}


.group-23 {
  height: 212px;
  left: 84px;
  position: absolute;
  top: 1262px;
  width: 184px;
}

 .group-24 {
  box-shadow: -2px 8px 24px #4949491a;
  height: 212px;
}

 .div-23 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 212px;
  justify-content: center;
  padding: 16px;
  position: relative;
  width: 184px;
}

 .add-tags-to-spam-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-top: -0.41px;
  position: relative;
  width: 100%;
}

 .add-tags-to-spam {
  color: var(--primaryn-100);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.81px;
  position: relative;
  text-align: center;
  width: fit-content;
}

 .group-25 {
  height: 122.82px;
  margin-bottom: -0.41px;
  position: relative;
  width: 116.71px;
}

 .overlap-group-7 {
  height: 123px;
  position: relative;
  width: 117px;
}

 .contacts-line-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 11.14px;
  justify-content: flex-end;
  left: 50px;
  position: absolute;
  top: 0;
}

 .contacts-line {
  align-items: center;
  background-color: #c2c2c233;
  border-radius: 41.78px;
  display: flex;
  gap: 12.86px;
  height: 61.71px;
  justify-content: center;
  padding: 15.43px;
  position: relative;
  width: 61.71px;
}

 .icon-instance-node {
  height: 40.09px !important;
  margin-bottom: -4.62px !important;
  margin-left: -4.62px !important;
  margin-right: -4.62px !important;
  margin-top: -4.62px !important;
  position: relative !important;
  width: 40.09px !important;
}

 .ic-alert-wrapper {
  align-items: center;
  background-color: #c2c2c233;
  border-radius: 41.78px;
  display: flex;
  gap: 12.86px;
  height: 62px;
  justify-content: center;
  left: 0;
  padding: 15.43px;
  position: absolute;
  top: 35px;
  width: 62px;
}

 .contacts-line-wrapper-2 {
  align-items: center;
  display: inline-flex;
  gap: 11.14px;
  height: 62px;
  left: 55px;
  position: absolute;
  top: 61px;
}


.div-16 {
align-items: center;
background-color: #dcdcdc;
border-radius: 24px;
box-shadow: -2px 8px 24px #4a4a4a1a;
display: flex;
flex-direction: column;
gap: 16px;
height: 224px;
left: 0;
overflow: hidden;
padding: 16px 16px 0px;
position: absolute;
top: 923px;
width: 184px;
}

.smarter-wrapper {
align-items: flex-start;
display: inline-flex;
flex: 0 0 auto;
gap: 6.48px;
position: relative;
}

.smarter {
color: #0c1922;
font-family: "DM Sans", Helvetica;
font-size: 16px;
font-weight: 900;
letter-spacing: 0;
line-height: normal;
margin-top: -0.81px;
position: relative;
text-align: center;
width: fit-content;
}

.image-wrapper-2 {
background-color: #ffffff;
border-radius: 17.82px;
height: 178px;
overflow: hidden;
position: relative;
width: 140px;
}

.image-3 {
height: 150px;
left: 0;
position: absolute;
top: 0;
width: 140px;
}


.overlap-group-wrapper {
height: 144px;
overflow: hidden;
position: relative;
width: 152px;
}


.phone {
background-color: #ffffff;
border: 3.04px solid;
border-color: var(--lightreliancegrey-100);
border-radius: 12.16px;
box-shadow: inset 0px 2.43px 1.22px #00000040;
height: 243px;
left: 20px;
position: absolute;
top: 0;
width: 109px;
}











.div-11 {
align-items: center;
background-color: #ffffff;
border-radius: 24px;
box-shadow: -2px 8px 24px #4a4a4a1a;
display: flex;
flex-direction: column;
gap: 9.6px;
height: 222px;
left: 411px;
overflow: hidden;
padding: 16.01px 16.01px 0px;
position: absolute;
top: 1306px;
width: 184px;
}

.home-wrapper-5 {
align-items: center;
background-color: #ffffff;
border-radius: 9.48px 9.48px 0px 0px;
display: flex;
flex: 0 0 auto;
flex-direction: column;
gap: 13.04px;
padding: 9.48px 6.32px;
position: relative;
width: 142.2px;
}

.home-wrapper-6 {
align-items: flex-start;
align-self: stretch;
display: flex;
flex: 0 0 auto;
flex-direction: column;
gap: 9.48px;
position: relative;
width: 100%;
}

.home-wrapper-7 {
align-items: center;
align-self: stretch;
display: flex;
flex: 0 0 auto;
flex-direction: column;
gap: 12.64px;
position: relative;
width: 100%;
}

.div-12 {
align-items: center;
display: flex;
flex: 0 0 auto;
flex-direction: column;
gap: 8px;
position: relative;
width: 102.7px;
}

.numpad {
height: 110.62px;
margin-left: -0.11px;
margin-right: -0.11px;
position: relative;
width: 102.92px;
}

.div-13 {
align-items: flex-start;
display: flex;
flex-direction: column;
gap: 9.48px;
position: relative;
width: 103px;
}

.div-14 {
align-items: center;
align-self: stretch;
display: flex;
flex: 0 0 auto;
gap: 30.81px;
position: relative;
width: 100%;
}

.number-input-instance {
height: 20.54px !important;
width: 12.94px !important;
}

.design-component-instance-node {
font-size: 12.6px !important;
line-height: 12.6px !important;
margin-top: -0.4px !important;
}

.number-input-2 {
height: 9.48px !important;
margin-bottom: -1.94px !important;
position: relative !important;
width: 9.48px !important;
}

.number-input-3 {
color: #000000 !important;
font-size: 6.3px !important;
line-height: 7.6px !important;
margin-bottom: -0.06px !important;
margin-left: -0.03px !important;
margin-right: -0.03px !important;
}

.number-input-4 {
color: #000000 !important;
font-size: 6.3px !important;
line-height: 7.6px !important;
margin-bottom: -0.06px !important;
}

.number-input-5 {
color: #000000 !important;
font-size: 6.3px !important;
line-height: 7.6px !important;
margin-bottom: -0.06px !important;
margin-left: -1.03px !important;
margin-right: -1.03px !important;
}

.div-15 {
align-items: center;
align-self: stretch;
display: flex;
flex: 0 0 auto;
gap: 29.63px;
position: relative;
width: 100%;
}